import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  TextField,
  MenuItem,
  Autocomplete,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  CircularProgress,
  Alert,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import firestoreRepository, {
  getAllPatients,
  getGlobalPreferences,
  updateLastDeviceSync,
} from "../../repositories/firestoreRepository";
import importPatient from "../dashboard/importActive.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, parse } from "date-fns";
import { enUS } from "date-fns/locale";
import { serverTimestamp, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import addImage from "../../images/upload/csv_import.svg";
import addImageActive from "../../images/upload/csv_active.svg";
import addImageRejected from "../../images/upload/csv_rejected.svg";
import { CheckCircle, EastOutlined } from "@mui/icons-material";
import "../../style/patient.css";
import ind_import from "../../images/dashboard/ind_import.svg";
import csvIcon from "../../images/upload/csv_icon.svg";
import deleteCsv from "../../images/upload/delete_csv.svg";
import "../../style/modal.css";
import {
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table as TableVirtual,
} from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { debounce } from "lodash";
import pLimit from "p-limit";
import errorIcon from "../../images/dashboard/error_session.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
    backgroundColor: "#FDFDFD",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: "20px",
  borderColor: "#8A8787",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
  padding: "20px",
};

const focusedStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const acceptStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const rejectStyle = {
  borderColor: "#ff1744",
  background: "rgba(255, 23, 68, 0.03)",
};

const tableStyle = {
  fontSize: "14px",
  overflow: "auto",
  width: "fit-content",
  marginLeft: "auto",
  marginRight: "auto",
};

const headerStyle = {
  textTransform: "none",
  fontSize: "14px",
};

const CSVUpload = ({
  entityId,
  isOrgUser,
  ownerIds,
  techIds,
  patientIds,
  tags,
  wrtPermissions,
  rdPermissions,
}) => {
  const [csvData, setCsvData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCsvData, setOpenCsvData] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFields, setSelectedFields] = useState({});
  const [selectedFieldData, setSelectedFieldData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [sessionsPreference, setSessionsPreference] = useState([]);
  const [csvName, setCsvName] = useState("");
  const [csvSize, setCsvSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [creatingSessions, setCreatingSessions] = useState(false);
  const [creationMessage, setCreationMessage] = useState(false);
  const [sessionsCreated, setSessionsCreated] = useState(0);

  const handleClose = () => {
    if (csvData.length > 0) {
      setOpenSave(true);
    } else {
      setOpen(false);
    }
  };
  const handleCloseSave = () => setOpenSave(false);

  const steps = [
    "Import Data",
    "CSV fields mapping",
    "Review Data & Create Sessions",
  ];
  const columnHeaders = [
    "Date",
    "Time",
    "Patient ID",
    "Clinician",
    "Technician",
    "Description",
    "Tags",
    "Procedure ID",
    "Eye Laterality",
  ];

  const headerMapping = {
    date: "Date",
    time: "Time",
    patientId: "Patient ID",
    owner: "Clinician",
    technician: "Technician",
    description: "Description",
    tags: "Tags",
    eyeLaterality: "Eye Laterality",
    procedureId: "Procedure ID",
  };

  const fieldOptions = [
    { label: "Clinician", value: "owner" },
    { label: "Technician", value: "technician" },
    { label: "Patient ID", value: "patientId" },
    { label: "Description", value: "description" },
    { label: "Tags", value: "tags" },
    { label: "Date", value: "date" },
    { label: "Time", value: "time" },
    { label: "Eye Laterality", value: "eyeLaterality" },
    { label: "Procedure ID", value: "procedureId" },
    { label: "Not included", value: "null" },
  ];

  const isAllowedToWrite = wrtPermissions === 2 ? true : false;

  const reverseKeyValuePairs = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[value] = key;
      return acc;
    }, {});
  };

  const fetchData = async () => {
    if (isOrgUser && rdPermissions && rdPermissions !== 0) {
      const patientsData = await getAllPatients(entityId, isOrgUser);
      const tempPatients = {};
      patientsData.forEach((patient) => {
        const globalId = patient.globalId;
        const canonicalId = patient.canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      const preferencesData = await getGlobalPreferences(entityId, isOrgUser);

      if (preferencesData && preferencesData.sessionImportFields) {
        const reversedSessionImportFields = reverseKeyValuePairs(
          preferencesData.sessionImportFields
        );
        setSelectedFields(reversedSessionImportFields);
        if (csvData && csvData.length > 0) {
          const updatedFieldData = csvData.map((row) => {
            const newRow = {};
            Object.entries(preferencesData.sessionImportFields).forEach(
              ([key, value]) => {
                const header = headerMapping[key];
                newRow[header] = row[value] || "";
              }
            );
            return newRow;
          });
          setSelectedFieldData(updatedFieldData);
        }
      }
      setSessionsPreference(preferencesData);

      setPatientIdDuplicate(tempPatients);
    }
  };

  const selectedFieldDataMap = new Map(
    selectedFieldData.map((row, index) => [index, row])
  );
  useEffect(() => {
    fetchData();
  }, [entityId, isOrgUser, csvData]);

  useEffect(() => {
    setValidationError("");
  }, [selectedRows, activeStep]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (creatingSessions === true) {
        e.preventDefault();
        e.returnValue = "";
        return "Please wait for the sessions to finish uploading.";
      }
    };

    window.addEventListener("hashchange", handleBeforeUnload);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("hashchange", handleBeforeUnload);
    };
  }, [creatingSessions]);

  useEffect(() => {
    // Clean selectedFields if they don't match the current CSV key
    const csvHeaders = csvData.length > 0 ? Object.keys(csvData[0]) : [];
    Object.keys(selectedFields).forEach((key) => {
      if (!csvHeaders.includes(key)) {
        setSelectedFields((prev) => {
          const newSelectedFields = { ...prev };
          delete newSelectedFields[key];
          return newSelectedFields;
        });
      }
    });
  }, [fieldOptions, selectedFields]);

  useEffect(() => {
    if (csvData.length > 0) {
      const updatedFieldData = csvData.map((row) => {
        const newRow = {};
        Object.entries(selectedFields).forEach(([key, value]) => {
          const header =
            fieldOptions.find((option) => option.value === value)?.label ||
            value;
          newRow[header] = row[key] || "";
        });
        return newRow;
      });
      setSelectedFieldData(updatedFieldData);
    }
  }, [selectedFields, csvData]);

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);

    const file = acceptedFiles[0];
    const fileName = file.name;
    const fileSize = formatFileSize(file.size);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
        setCsvName(fileName);
        setCsvSize(fileSize);
        setSelectedRows(results.data.map((_, index) => index));
        setLoading(false);
      },
    });
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    setOpenSave(false);
    setCsvData([]);
    setActiveStep(0);
    setSelectedRows([]);
    setSelectedFields({});
    setSelectedFieldData([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { "text/csv": [] },
      maxFiles: 1,
    });

  const getStyle = (isFocused, isDragAccept, isDragReject) => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  });

  const style = getStyle(isFocused, isDragAccept, isDragReject);

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((i) => i !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === csvData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(csvData.map((_, index) => index));
    }
  };

  const handleFieldChange = (key, value) => {
    const selectedOption = fieldOptions.find(
      (option) => option.value === value
    );
    const label = selectedOption ? selectedOption.label : value;

    setSelectedFields((prevSelectedFields) => {
      const updatedFields = { ...prevSelectedFields, [key]: value };
      return updatedFields;
    });
    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = prevSelectedFieldData.map((row, rowIndex) => {
        const newRow = { ...row };
        // Overwrite only the value for the given field key
        newRow[label] = csvData[rowIndex][key] || "";
        return newRow;
      });
      return updatedFieldData;
    });
  };

  const handleDataChange = (rowIndex, header, value) => {
    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = [...prevSelectedFieldData];
      updatedFieldData[rowIndex] = {
        ...updatedFieldData[rowIndex],
        [header]: value,
      };
      return updatedFieldData;
    });
    setValidationError("");
  };

  const resetModalState = () => {
    setCsvData([]);
    setCsvName("");
    setCsvSize(0);
    setSelectedFieldData([]);
    setSelectedRows([]);
    setSelectedFields({});
  };

  const createNewPreferences = async () => {
    if (csvData && csvData.length > 0) {
      const selectedFieldsPreference = Object.entries(selectedFields).reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== undefined) {
            acc[value] = key; // Reverse mapping: value becomes key
          } else {
            console.log(`Skipping null/undefined value for key: ${key}`);
          }
          return acc;
        },
        {}
      );

      const sessionImportFields = selectedFieldsPreference;
      const preferenceData = {
        sessionImportFields: sessionImportFields,
      };
      if (sessionsPreference !== null) {
        await firestoreRepository.updatePreference(
          entityId,
          isOrgUser,
          preferenceData
        );
      } else {
        await firestoreRepository.createPreference(
          entityId,
          isOrgUser,
          preferenceData
        );
      }
    } else {
      console.error("No data to create preference");
    }
  };

  const createNewSessions = async () => {
    const BATCH_SIZE = 100; // MAX Value 250 -> Firestore batch size limit / 2 (Each row may lead to 2 writes, patient and session)
    const MAX_CONCURRENT_CHUNKS = 5; // Limit the number of parallel chunks
    setValidationError("");
    setSessionsCreated(0); // Reset the sessions created count

    try {
      // Preload patient data
      const patientDataMap = new Map(
        await Promise.all(
          selectedRows.map(async (index) => {
            const row = selectedFieldData[index];
            const patientId = row?.["Patient ID"];
            if (!patientId) return [null, null];

            const patient = await firestoreRepository.getPatientByCanonicalId(
              entityId,
              isOrgUser,
              patientId
            );
            return [patientId, patient];
          })
        )
      );

      // Validate all rows before processing
      const uniquePatientIds = new Set();
      const uniqueSelectedRows = selectedRows.filter((index) => {
        const row = selectedFieldData[index] || {};
        const patientId = row["Patient ID"];
        if (!patientId || uniquePatientIds.has(patientId)) {
          return false;
        }
        uniquePatientIds.add(patientId);
        return true;
      });
      for (const index of uniqueSelectedRows) {
        const row = selectedFieldData[index] || {};
        const patientId = row["Patient ID"];
        const dateStr = row["Date"];
        const timeStr = row["Time"];
        const date = parseDate(dateStr);
        const time = parseTime(timeStr);

        if (!patientId || !dateStr || !timeStr || !date || !time) {
          throw new Error("Date, Time, and Patient ID must be included.");
        }
      }

      // Set creation states after validation
      setCreatingSessions(true);
      setCreationMessage(true);

      // Break rows into chunks of size BATCH_SIZE
      const chunks = [];
      for (let i = 0; i < uniqueSelectedRows.length; i += BATCH_SIZE) {
        chunks.push(uniqueSelectedRows.slice(i, i + BATCH_SIZE));
      }

      // Limit the number of concurrent chunk processing
      const limit = pLimit(MAX_CONCURRENT_CHUNKS);

      const chunkPromises = chunks.map((chunk, chunkIndex) =>
        limit(async () => {
          const batch = writeBatch(db);

          for (const index of chunk) {
            const row = selectedFieldData[index] || {};
            const patientId = row["Patient ID"];
            const description = row["Description"] || "";
            const tagsSelected = Array.isArray(row["Tags"])
              ? row["Tags"]
              : row["Tags"]
              ? row["Tags"].split(",")
              : [];
            const dateStr = row["Date"];
            const timeStr = row["Time"];
            const eyeLaterality = row["Eye Laterality"] || "";
            const procedureId = row["Procedure ID"] || "";
            const ownerName = row["Clinician"];
            const owner =
              ownerName === "Unassigned"
                ? ""
                : ownerIds.find((o) => o.id === ownerName)?.id || "";
            const technicianName = row["Technician"];
            const technician =
              technicianName === "Unassigned"
                ? ""
                : techIds.find((t) => t.id === technicianName)?.id || "";
            const date = parseDate(dateStr);
            const time = parseTime(timeStr);
            const dateTimeStr = `${convertToISO(date)}T${convertTimeToISO(
              time
            )}:00`;
            const dateTime = new Date(dateTimeStr);
            const patient = patientDataMap.get(patientId);
            let globalPatientId = patient ? patient.globalId : null;
            const validEyeLaterality = validateEyeLaterality(eyeLaterality);

            if (!patient) {
              globalPatientId = await firestoreRepository.createPatientInBatch(
                entityId,
                isOrgUser,
                batch,
                patientId,
                {}
              );
            } else if (patient.patientId !== patientId) {
              await firestoreRepository.updatePatientInBatch(
                entityId,
                isOrgUser,
                batch,
                patient.globalId,
                patientId,
                {}
              );
            }

            const sessionData = {
              patientGlobalId: globalPatientId,
              description,
              tags: tagsSelected,
              createdAt: dateTime,
              modifiedAt: serverTimestamp(),
              owner,
              eyeLaterality: validEyeLaterality,
              procedureId,
              technician,
            };

            await firestoreRepository.createSessionInBatch(
              entityId,
              isOrgUser,
              batch,
              sessionData
            );

            setSessionsCreated((prev) => prev + 1);

            // Yield control back to the main thread after each row
            await Promise.resolve();
            // Yield control back to the main thread to update the UI
            await new Promise((resolve) => setTimeout(resolve, 0));
          }
          await batch.commit();
        })
      );

      await Promise.all(chunkPromises);

      // Final UI updates
      setSessionsCreated(uniqueSelectedRows.length); // Ensure it reflects 100% at the end
      setActiveStep(0);
      resetModalState();
      setOpen(false);
      handleCloseSave();
    } catch (error) {
      console.error("Error creating sessions:", error);
      setValidationError(
        error.message || "An unexpected error occurred while creating sessions."
      );
    } finally {
      setCreatingSessions(false);
      setCreationMessage(false);
      updateLastDeviceSync(entityId, isOrgUser);
    }
  };

  const validateEyeLaterality = (value) => {
    const validEyeLaterality = ["os", "od", "ou"];
    if (value && validEyeLaterality.includes(value.toLowerCase())) {
      return value.toLowerCase();
    }
    return "";
  };

  const parseEyeLaterality = (value) => {
    if (value) {
      return value.toUpperCase();
    }
    return "";
  };

  const convertToISO = (date) => {
    // Validate if the date is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) return "";
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of today
    const oneYearFromToday = new Date();
    oneYearFromToday.setFullYear(today.getFullYear() + 1);

    // Check if the date falls within the allowed range
    if (date < today || date > oneYearFromToday) {
      return ""; // Return an empty string if the date is out of range
    }

    return format(date, "yyyy-MM-dd"); // Return ISO format
  };

  const parseDate = (value) => {
    const parsedDate = Date.parse(value);
    const date = new Date(parsedDate);

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of today

    const oneYearFromToday = new Date();
    oneYearFromToday.setFullYear(today.getFullYear() + 1);
    if (isNaN(parsedDate)) {
      return null;
    } else if (date < today || date > oneYearFromToday) {
      return null; // Return null if the date is out of range
    } else {
      return date;
    }
  };

  const convertTimeToISO = (time) => {
    if (!(time instanceof Date) || isNaN(time)) return "";
    return format(time, "HH:mm");
  };

  const parseTime = (timeString) => {
    const formats = [
      "HH:mm", // 24-hour format (e.g., "14:30")
      "h:mm a", // 12-hour format with AM/PM (e.g., "2:30 PM")
      "HH:mm:ss", // 24-hour with seconds (e.g., "14:30:45")
      "h:mm:ss a", // 12-hour with seconds and AM/PM (e.g., "2:30:45 PM")
    ];
    for (let formatString of formats) {
      const parsedTime = parse(timeString, formatString, new Date(), {
        locale: enUS,
      });

      if (isValid(parsedTime)) {
        return parsedTime;
      } else {
        return null;
      }
    }
  };

  const cacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 60,
      minHeight: 60,
    })
  );

  // Debounced measure function
  const debouncedMeasure = useRef(
    debounce((measure) => measure(), 100)
  ).current;

  useEffect(() => {
    cacheRef.current.clearAll();
  }, [csvData]);

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedMeasure.cancel();
    };
  }, [debouncedMeasure]);

  const cellStyle = (header, value) => {
    const isInvalidDate = header === "Date" && parseDate(value) === null;
    const isInvalidTime = header === "Time" && parseTime(value) === null;
    const isEmpty = !value;

    return {
      padding: "5px 10px",
      fontSize: "10px",
      borderRadius: "10px",
      backgroundColor:
        isEmpty || isInvalidDate || isInvalidTime ? "#FFB6B666" : "inherit",
    };
  };

  return (
    <>
      <div>
        {isOrgUser ? (
          <Tooltip
            title={
              isAllowedToWrite
                ? ""
                : "You do not have permission to perform this action"
            }
          >
            <button
              className={`button_no_style button-upload-style ${
                !isAllowedToWrite ? "button-disabled" : ""
              }`}
              disabled={!isAllowedToWrite}
              onClick={() => isAllowedToWrite && setOpen(true)}
            >
              <div className="flex-center">
                <IconButton
                  type="button"
                  className="icon-import-button"
                  aria-label="search"
                >
                  <img src={importPatient} className="icon-import-image" />
                </IconButton>
                <div className="import-text">Import</div>
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            className="button_no_style button-business-style"
            onClick={() => setOpenBusiness(true)}
          >
            <div className="flex-center">
              <IconButton
                type="button"
                className="icon-import-button"
                aria-label="search"
              >
                <img src={importPatient} className="icon-import-image" />
              </IconButton>
              <div className="import-text">Import</div>
            </div>
          </button>
        )}
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={creationMessage}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div style={{ padding: "30px 20px" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBottom: "1em",
                    }}
                  >
                    Creation in progress...{" "}
                  </div>
                  <Alert
                    severity="error"
                    style={{ backgroundColor: "#f8d7da", color: "#FF284F" }}
                    icon={<img src={errorIcon} style={{ maxWidth: "100%" }} />}
                  >
                    Don't leave this page or the creation will be interrupted
                  </Alert>
                  <div style={{ marginTop: "2em" }}>
                    <div>{sessionsCreated} sessions created ...</div>
                    <LinearProgress
                      variant="determinate"
                      value={(sessionsCreated / selectedRows.length) * 100}
                      sx={{
                        marginTop: "10px",
                        height: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F1E7FF", // Set the background color of the progress bar

                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#8F4FFF",
                          borderRadius: "10px",
                          height: "10px",
                        },
                      }}
                    />
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={() => setOpenCsvData(false)}
          aria-labelledby="customized-dialog-title"
          open={openCsvData}
          maxWidth={"100%"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub modal-csv-data"
        >
          <BootstrapDialogContent
            onClose={() => setOpenCsvData(false)}
            dividers
            className="dialog-content"
            style={{ padding: "0" }}
          >
            <>
              <Box className="box-style">
                <div className="csv-name">{csvName}</div>
                <TableContainer
                  style={{
                    borderRadius: "10px",
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "1em",
                    minWidth: "1000px",
                    maxHeight: "65vh",
                    overflow: "auto",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow className="table_header">
                        {csvData.length > 0 &&
                          Object.keys(csvData[0]).map((header, index) => (
                            <TableCell
                              key={index}
                              style={{
                                fontWeight: "600",
                                backgroundColor: "#e2e2e2",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvData.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {Object.values(row).map((value, cellIndex) => (
                            <TableCell key={cellIndex}>{value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex-end-container">
                  <button
                    className="button button-close-csv"
                    onClick={() => setOpenCsvData(false)}
                  >
                    Close
                  </button>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={() => setOpenBusiness(false)}
          aria-labelledby="customized-dialog-title"
          open={openBusiness}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub modal-csv-data"
        >
          <BootstrapDialogContent
            onClose={() => setOpenBusiness(false)}
            dividers
            className="dialog-content"
            style={{ padding: "0" }}
          >
            <>
              <Box>
                <div>
                  <div className="multi-user-access">
                    Empower Your Team with Multi-User Access!{" "}
                  </div>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className="grid-item"
                      sx={{
                        order: { xs: 2, md: 1 },
                      }}
                    >
                      <div className="text-left">
                        <p>
                          Designed for organizations, offering seamless{" "}
                          <b>multi-user access and customizable permissions.</b>
                        </p>
                        <p>
                          Experience simplified data management tailored for
                          your team's needs!
                        </p>{" "}
                        <a
                          href="mailto:maria@customsurgical.co"
                          style={{
                            backgroundColor: "white",
                            color: "#9747FF",
                            border: "none",
                            borderRadius: "10px",
                            padding: "10px 0px",
                            cursor: "pointer",
                            fontWeight: "bold",
                            width: "90%",
                            marginTop: "5em",
                            textAlign: "center",
                            display: "inline-block",
                            textDecoration: "none",
                          }}
                        >
                          Join the Pilot Program!
                        </a>
                      </div>{" "}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      sx={{
                        order: { xs: 1, md: 2 },
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "1em",
                          marginBottom: "2em",
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#8F4FFF",
                        }}
                      >
                        <span>MicroREC Connect</span>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Business Tier
                        </span>
                      </div>
                      <img src={ind_import} style={{ maxWidth: "100%" }}></img>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={() => setOpenSave(false)}
          aria-labelledby="customized-dialog-title"
          open={openSave}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={() => setOpenSave(false)}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div style={{ padding: "20px" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBottom: "1em",
                    }}
                  >
                    Save Progress?
                  </div>
                  <div>
                    Would you like to save your progress before exiting? You can
                    resume from where you left off when you return.
                  </div>
                </div>
                <hr style={{ border: "1px solid #EFEFEF" }}></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2em",
                    marginBottom: "1em",
                  }}
                >
                  <button
                    style={{
                      width: "fit-content",
                      border: "1px solid #D9D9D9",
                      backgroundColor: "white",
                      padding: "10px 35px",
                      color: "#3D3838",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={handleCloseSave}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      width: "fit-content",
                      backgroundColor: "#F1E7FF",
                      padding: "10px 35px",
                      color: "#3D3838",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={handleModalClose}
                  >
                    Clear&Exit
                  </button>
                  <button
                    style={{
                      width: "fit-content",
                      backgroundColor: "#8F4FFF",
                      padding: "10px 35px",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={() => {
                      setOpenSave(false);
                      setOpen(false);
                    }}
                  >
                    Save&Exit
                  </button>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"100%"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "0.5em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Create New Sessions
                    </div>
                  </div>
                </div>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: activeStep === index ? "#8F4FFF" : "inherit",
                            fontWeight:
                              activeStep === index ? "600" : "inherit",
                          },
                          "& .MuiStepIcon-root": {
                            color: activeStep === index ? "#8F4FFF" : "inherit",
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "#8F4FFF",
                          },
                          "& .Mui-completed .MuiStepIcon-root": {
                            color: "#8F4FFF",
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {activeStep === 0 && (
                  <div style={{ padding: "15px" }}>
                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        padding: "5px",
                        marginTop: "0.5em",
                        marginBottom: "0.5em",
                        borderRadius: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div style={{ padding: "10px", fontSize: "14px" }}>
                        Example of the CSV file:{" "}
                      </div>
                      <TableContainer
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Table>
                          <TableHead className="table_header">
                            <TableRow>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Date (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Time (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Patient ID (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Clinician
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Technician
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Description
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Tags
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Procedure ID
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Eye Laterality
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>2023-12-15</TableCell>
                              <TableCell>14:30</TableCell>
                              <TableCell>P12345</TableCell>
                              <TableCell>John Doe</TableCell>
                              <TableCell>John Doe</TableCell>
                              <TableCell>Routine check-up</TableCell>
                              <TableCell>follow-up</TableCell>
                              <TableCell>23456</TableCell>
                              <TableCell>OS</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>2023-12-16</TableCell>
                              <TableCell>10:00</TableCell>
                              <TableCell>P23456</TableCell>
                              <TableCell>John Smith</TableCell>
                              <TableCell>John Smith</TableCell>
                              <TableCell>First consultation</TableCell>
                              <TableCell>cataract</TableCell>
                              <TableCell>12345</TableCell>
                              <TableCell>OD</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div style={{ padding: "10px", fontSize: "13px" }}>
                        (*) Make sure the file includes Patient ID, date and
                        time.
                      </div>
                    </div>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <div className="upload-div">
                        {isFocused || isDragAccept ? (
                          <img src={addImageActive}></img>
                        ) : isDragReject || !isAllowedToWrite ? (
                          <img src={addImageRejected}></img>
                        ) : (
                          <img src={addImage}></img>
                        )}
                        <div className="upload-p">Add csv</div>
                      </div>
                      <div>or drop file to upload</div>
                      <i className="upload-i">We currently support CSV.</i>
                    </div>
                    {csvData.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "0.5em",
                          backgroundColor: "#F5F5F5",
                          padding: "10px 20px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={csvIcon}></img>
                          <div
                            style={{ padding: "0px 25px", fontWeight: "600" }}
                          >
                            {csvName}
                          </div>
                          <div style={{ color: "#999999", fontSize: "14px" }}>
                            {csvSize}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <div
                                onClick={() => setOpenCsvData(true)}
                                style={{
                                  color: "#8F4FFF",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontWeight: "600",
                                }}
                              >
                                View data
                              </div>
                              <CheckCircle
                                style={{
                                  color: "#8F4FFF",
                                  padding: "0px 20px",
                                }}
                              />
                            </>
                          )}

                          <button
                            className="button_no_style"
                            onClick={() => setCsvData([])}
                          >
                            <img src={deleteCsv} alt="Delete CSV"></img>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "0.5em",
                        marginBottom: "0.5em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={handleModalClose}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor:
                            csvData.length === 0 ? "#D9D9D9" : "#8F4FFF",
                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          if (csvData.length > 0) {
                            setActiveStep(1);
                          }
                        }}
                        disabled={csvData.length === 0}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 1 && (
                  <div style={{ padding: "20px" }}>
                    <Grid
                      container
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                      }}
                    >
                      <div
                        style={{
                          color: "#797979",
                          marginBottom: "1em",
                          fontSize: "14px",
                        }}
                      >
                        Ensure columns from your file are mapped correctly to
                        session properties{" "}
                      </div>
                      <div
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={4} style={{ fontWeight: "500" }}>
                          Columns in your file{" "}
                        </Grid>
                        <Grid item xs={2.5} style={{ fontWeight: "500" }}>
                          CSV Data{" "}
                        </Grid>
                        <Grid item xs={1.5}>
                          {""}
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: "500" }}>
                          Session fields
                        </Grid>
                      </div>
                      <div
                        style={{ overflowY: "scroll", height: "38vh" }}
                        className="hide-scrollbar"
                      >
                        {Object.keys(csvData[0]).map((key) => (
                          <div
                            key={key}
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "2em",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <Grid item xs={12} sm={4}>
                              <div
                                style={{
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                  padding: "10px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "1em",
                                }}
                              >
                                <img src={addImage}></img>
                                <span style={{ marginLeft: "1em" }}>{key}</span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={2.5}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10px",
                                  boxShadow: "0px 2px 8px 0px #3A5C9D24",
                                  padding: "10px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {csvData.slice(0, 1).map((row, index) => (
                                  <div key={index}>{row[key]}</div>
                                ))}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={1.5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <EastOutlined />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                size="small"
                                select
                                label="Select field"
                                fullWidth
                                variant="outlined"
                                style={{
                                  borderRadius: "10px",
                                }}
                                value={selectedFields[key] || ""}
                                onChange={(e) =>
                                  handleFieldChange(key, e.target.value)
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: "custom-label",
                                    focused: "custom-label-focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: "custom-notchedOutline",
                                    focused: "custom-focused",
                                  },
                                }}
                              >
                                {fieldOptions
                                  .filter(
                                    (option) =>
                                      option.value === "null" ||
                                      !Object.values(selectedFields).includes(
                                        option.value
                                      ) ||
                                      selectedFields[key] === option.value
                                  )
                                  .map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginBottom: "1em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => setActiveStep(0)}
                      >
                        Back
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor: "#8F4FFF",
                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          setActiveStep(2);
                          createNewPreferences();
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div style={{ padding: "20px" }}>
                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        padding: "5px",
                        borderRadius: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "0.5em",
                        width: "fit-content",
                      }}
                    >
                      <div style={{ padding: "10px", fontSize: "14px" }}>
                        Review the data, and update any field that is incorrect:
                      </div>
                      <div
                        style={{
                          height: "350px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <TableVirtual
                          width={1400}
                          height={330}
                          headerHeight={40}
                          rowHeight={cacheRef.current.rowHeight}
                          deferredMeasurementCache={cacheRef.current}
                          rowCount={selectedFieldDataMap.size}
                          rowGetter={({ index }) => {
                            const rowData =
                              selectedFieldDataMap.get(index) || {};
                            return rowData;
                          }}
                          rowClassName="tableRowHover"
                          style={tableStyle}
                          headerStyle={headerStyle}
                        >
                          <Column
                            label={
                              <Checkbox
                                checked={selectedRows.length === csvData.length}
                                onChange={handleSelectAll}
                                style={{ color: "#8F4FFF" }}
                              />
                            }
                            dataKey="checkbox"
                            width={50}
                            cellRenderer={({ rowIndex }) => (
                              <Checkbox
                                checked={selectedRows.includes(rowIndex)}
                                onChange={() => handleCheckboxChange(rowIndex)}
                                style={{ color: "#8F4FFF" }}
                              />
                            )}
                          />
                          {columnHeaders.map((header, index) => (
                            <Column
                              key={header}
                              label={
                                header === "Date" ||
                                header === "Time" ||
                                header === "Patient ID"
                                  ? `${header} (*)`
                                  : header
                              }
                              dataKey={header}
                              width={
                                header === "Date"
                                  ? 115
                                  : header === "Time"
                                  ? 70
                                  : header === "Eye Laterality"
                                  ? 130
                                  : header === "Tags"
                                  ? 180
                                  : 170
                              }
                              cellRenderer={({ rowIndex, parent }) => (
                                <CellMeasurer
                                  cache={cacheRef.current}
                                  columnIndex={0}
                                  key={`${rowIndex}-${index}`}
                                  parent={parent}
                                  rowIndex={rowIndex}
                                >
                                  {({ measure }) => {
                                    const rowData =
                                      selectedFieldDataMap.get(rowIndex) || {};
                                    const value = rowData[header] || "";

                                    return header === "Date" ? (
                                      <DatePicker
                                        selected={
                                          value ? parseDate(value) : null
                                        }
                                        onChange={(date) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            date ? convertToISO(date) : ""
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        minDate={new Date()}
                                        maxDate={
                                          new Date(
                                            new Date().setFullYear(
                                              new Date().getFullYear() + 1
                                            )
                                          )
                                        }
                                        customInput={
                                          <TextField
                                            className="tableCellHover"
                                            variant="standard"
                                            size="small"
                                            InputProps={{
                                              disableUnderline: true,
                                              endAdornment:
                                                !value ||
                                                parseDate(value) === null ? (
                                                  <InputAdornment position="end">
                                                    <img
                                                      src={errorIcon}
                                                      style={{
                                                        maxWidth: "100%",
                                                      }}
                                                    />
                                                  </InputAdornment>
                                                ) : null,
                                              style: { fontSize: "14px" },
                                            }}
                                            style={cellStyle(header, value)}
                                          />
                                        }
                                        popperProps={{
                                          container: document.body,
                                          style: { zIndex: 20000 },
                                        }}
                                        portalId="root-portal"
                                      />
                                    ) : header === "Time" ? (
                                      <DatePicker
                                        selected={
                                          value ? parseTime(value) : null
                                        }
                                        onChange={(time) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            time ? convertTimeToISO(time) : ""
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        customInput={
                                          <TextField
                                            value={value}
                                            className="tableCellHover"
                                            variant="standard"
                                            size="small"
                                            InputProps={{
                                              disableUnderline: true,
                                              endAdornment:
                                                parseTime(value) === null ? (
                                                  <InputAdornment position="end">
                                                    <img
                                                      src={errorIcon}
                                                      style={{
                                                        maxWidth: "100%",
                                                      }}
                                                    />
                                                  </InputAdornment>
                                                ) : null,
                                              style: { fontSize: "14px" },
                                            }}
                                            style={cellStyle(header, value)}
                                          />
                                        }
                                        popperProps={{
                                          container: document.body,
                                          style: { zIndex: 20000 },
                                        }}
                                        portalId="root-portal"
                                      />
                                    ) : header === "Patient ID" ? (
                                      <Autocomplete
                                        freeSolo
                                        options={patientIds.map(
                                          (patient) => patient.patientId
                                        )}
                                        value={value || ""}
                                        onInputChange={(e, newValue) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            newValue
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        onChange={(e, newValue) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            newValue
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="tableCellHover arrow-on-hover"
                                            variant="standard"
                                            size="small"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                              endAdornment: !value ? (
                                                <InputAdornment position="end">
                                                  <img
                                                    src={errorIcon}
                                                    style={{ maxWidth: "100%" }}
                                                  />
                                                </InputAdornment>
                                              ) : null,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                            }}
                                            style={{
                                              ...cellStyle(header, value),
                                              width: "80%",
                                            }}
                                          />
                                        )}
                                      />
                                    ) : header === "Clinician" ? (
                                      <TextField
                                        select
                                        value={
                                          ownerIds.some(
                                            (owner) =>
                                              owner.name === value ||
                                              owner.id === value
                                          )
                                            ? ownerIds.find(
                                                (owner) =>
                                                  owner.name === value ||
                                                  owner.id === value
                                              ).id
                                            : "Unassigned"
                                        }
                                        onChange={(e) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            e.target.value || "Unassigned"
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        className="tableCellHover arrow-on-hover"
                                        variant="standard"
                                        size="small"
                                        InputProps={{
                                          disableUnderline: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        SelectProps={{
                                          classes: {
                                            select: "no-background",
                                          },
                                        }}
                                        style={{
                                          padding: "5px 10px",
                                          width: "80%",
                                        }}
                                      >
                                        {ownerIds.map((owner) => (
                                          <MenuItem
                                            key={owner.id}
                                            value={owner.id}
                                          >
                                            {owner.name}
                                          </MenuItem>
                                        ))}
                                        <MenuItem value="Unassigned">
                                          Unassigned
                                        </MenuItem>
                                      </TextField>
                                    ) : header === "Technician" ? (
                                      <TextField
                                        select
                                        value={
                                          techIds.some(
                                            (owner) =>
                                              owner.name === value ||
                                              owner.id === value
                                          )
                                            ? techIds.find(
                                                (owner) =>
                                                  owner.name === value ||
                                                  owner.id === value
                                              ).id
                                            : "Unassigned"
                                        }
                                        onChange={(e) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            e.target.value || "Unassigned"
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        className="tableCellHover arrow-on-hover"
                                        variant="standard"
                                        size="small"
                                        InputProps={{
                                          disableUnderline: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        SelectProps={{
                                          classes: {
                                            select: "no-background",
                                          },
                                        }}
                                        style={{
                                          padding: "5px 10px",
                                          width: "80%",
                                        }}
                                      >
                                        {techIds.map((owner) => (
                                          <MenuItem
                                            key={owner.id}
                                            value={owner.id}
                                          >
                                            {owner.name}
                                          </MenuItem>
                                        ))}
                                        <MenuItem value="Unassigned">
                                          Unassigned
                                        </MenuItem>
                                      </TextField>
                                    ) : header === "Tags" ? (
                                      <Autocomplete
                                        multiple
                                        freeSolo
                                        options={tags}
                                        value={
                                          typeof value === "string"
                                            ? value
                                                .split(",")
                                                .filter(
                                                  (tag) => tag.trim() !== ""
                                                )
                                            : value
                                        }
                                        onChange={(e, newValue) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            newValue.filter(
                                              (tag) => tag.trim() !== ""
                                            )
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="tableCellHover arrow-on-hover"
                                            variant="standard"
                                            size="small"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                              style: {
                                                background: "none",
                                                border: "none",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                fontSize: "14px",
                                              },
                                            }}
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                            }}
                                            style={{
                                              padding: "5px 10px",
                                              width: "85%",
                                            }}
                                          />
                                        )}
                                      />
                                    ) : header === "Eye Laterality" ? (
                                      <TextField
                                        select
                                        labelId="eye-laterality-label"
                                        value={parseEyeLaterality(value) || ""}
                                        onChange={(e) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            e.target.value || "Unassigned"
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        className="tableCellHover arrow-on-hover"
                                        variant="standard"
                                        size="small"
                                        InputProps={{
                                          disableUnderline: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        SelectProps={{
                                          classes: {
                                            select: "no-background",
                                          },
                                        }}
                                        style={{
                                          padding: "5px 10px",
                                          width: "80%",
                                        }}
                                      >
                                        <MenuItem value="OS">OS</MenuItem>
                                        <MenuItem value="OD">OD</MenuItem>
                                        <MenuItem value="OU">OU</MenuItem>
                                      </TextField>
                                    ) : (
                                      <TextField
                                        value={value}
                                        onChange={(e) => {
                                          handleDataChange(
                                            rowIndex,
                                            header,
                                            e.target.value
                                          );
                                          debouncedMeasure(measure);
                                        }}
                                        className="tableCellHover"
                                        variant="standard"
                                        size="small"
                                        multiline={header === "Description"}
                                        InputProps={{
                                          disableUnderline: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        style={{
                                          padding: "5px 10px",
                                          width: "80%",
                                        }}
                                      />
                                    );
                                  }}
                                </CellMeasurer>
                              )}
                            />
                          ))}
                        </TableVirtual>
                      </div>
                    </div>

                    {validationError && (
                      <div style={{ color: "red", textAlign: "right" }}>
                        {validationError}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginBottom: "0em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => setActiveStep(1)}
                      >
                        Back
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor:
                            validationError ||
                            creatingSessions ||
                            selectedRows.length === 0
                              ? "#BDBDBD"
                              : "#8F4FFF",
                          color: creatingSessions ? "#7D7D7D" : "#FFFFFF",

                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          createNewSessions();
                        }}
                        disabled={
                          validationError ||
                          creatingSessions ||
                          selectedRows.length === 0
                        }
                      >
                        {creatingSessions
                          ? "Creating sessions..."
                          : `Create ${selectedRows.length} sessions`}
                      </button>
                    </div>
                  </div>
                )}
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CSVUpload;

import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import bug from "../images/dashboard/bugs.svg";
import question from "../images/dashboard/question.svg";
import { set } from "lodash";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (event) => {
      setHasError(true);
      console.error("ErrorBoundary caught an error", event.error);
    };

    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", handleError);

    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener("unhandledrejection", handleError);
    };
  }, []);

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom("showNewMessage", "I have issues loading a page");
    }
  };

  const goBackAndReload = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  if (hasError) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <img src={bug} style={{ maxWidth: "100%" }}></img>
        <h1>Oops!</h1>
        <h1
          style={{
            fontWeight: "500",
            marginTop: "0px",
            padding: "0px 20px",
            maxWidth: "550px",
            marginBottom: "0",
            fontSize: "26px",
          }}
        >
          We apologize for the inconvenience. Our system encountered an
          unexpected issue.
        </h1>
        <div
          style={{ padding: "10px 0px", fontSize: "20px", maxWidth: "600px" }}
        >
          Please refresh the page or try your action again. If the problem
          persists, our support team is here to help.
        </div>
        <div>
          <button
            onClick={goBackAndReload}
            style={{
              borderRadius: "20px",
              padding: "10px 30px",
              border: "1px solid #999999",
              marginRight: "15px",
              backgroundColor: "white",
              fontSize: "18px",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            Go Back
          </button>
          <button
            onClick={() => window.location.reload()}
            style={{
              backgroundColor: "#8F4FFF",
              borderRadius: "20px",
              padding: "10px 30px",
              border: "none",
              color: "white",
              fontSize: "18px",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            Try Again
          </button>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <img src={question} style={{ marginRight: "10px" }}></img>
          <div
            style={{ color: "#8F4FFF", cursor: "pointer" }}
            onClick={openIntercom}
          >
            Need help? Chat with our support team
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;

import React from "react";
import "../../style/dashboard.css";
import chat from "../../images/dashboard/chat.svg";
import restricted from "../../images/dashboard/restricted.svg";
import { Grid } from "@mui/material";
const RestrictedDashboard = () => {
  const handleChatClick = () => {
    window.Intercom(
      "showNewMessage",
      "I don't have access to read my organization data."
    );
  };
  return (
    <>
      <Grid
        container
        style={{
          padding: "40px",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={5}>
          <h1>Access Restricted</h1>
          <div style={{ fontSize: "22px" }}>
            You don't have permissions to see the data from this organization.
            Please contact your organization administrator for access permission
            or reach out to our support team if you need help.
          </div>
          <Grid item xs={12} marginTop={"2em"}>
            <button
              className="button_no_style contact_us_button"
              onClick={handleChatClick}
            >
              <img
                src={chat}
                style={{ height: "20px", marginRight: "10px" }}
              ></img>
              <div>Chat with Support</div>
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.5}
          marginTop={{ xs: 5, md: 0 }}
          marginLeft={{ xs: 0, md: 5 }}
        >
          <img src={restricted} style={{ maxWidth: "100%" }}></img>
        </Grid>
      </Grid>
    </>
  );
};

export default RestrictedDashboard;

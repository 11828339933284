import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { TextField, InputAdornment } from "@mui/material";
import firestoreRepository from "../../repositories/firestoreRepository";
import Layout from "../Layout";
import "./Dashboard.css";
import UploadPatientCSV from "../modals/uploadPatient";
import PatientCreation from "../common/ModalPatientCreation";
import "../../style/patient.css";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/system";
import PatientsTable from "./PatientsTable";
import { UploadContext } from "../App";
import FreeUser from "./free";
import FreeOrg from "./free_org";
import RestrictedDashboard from "./restricted";

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 30px;
    width: 300px;
    height: 40px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

export default function Dashboard() {
  const [patients, setPatients] = useState([]);
  const [rows, setRows] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const {
    entityId,
    isOrgUser,
    wrtPermissions,
    rdPermissions,
    orgDataDetails,
    largestSubscriptionId,
    orgData,
    userData,
  } = useContext(UploadContext);
  const genderMap = {
    0: "Unknown",
    1: "Male",
    2: "Female",
    9: "Other",
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [needReload, setNeedReload] = useState(false);

  useEffect(() => {
    if (
      entityId.current === null ||
      isOrgUser.current === null ||
      (isOrgUser.current && rdPermissions.current === undefined)
    ) {
      return;
    }

    if (isOrgUser.current && rdPermissions.current === 0) {
      return;
    } else if (
      entityId.current !== null &&
      isOrgUser.current !== null &&
      wrtPermissions.current !== null
    ) {
      fetchData();
    }
  }, [
    needReload,
    orgDataDetails,
    entityId.current,
    isOrgUser.current,
    rdPermissions.current,
  ]);

  const loadAllPatients = async (entityId, isOrgUser) => {
    const patientsData = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    return patientsData;
  };

  const loadAllSessions = async (entityId, isOrgUser) => {
    const sessionsData = await firestoreRepository.getAllSessions(
      entityId,
      isOrgUser
    );
    return sessionsData;
  };

  const loadAllTags = async (entityId, isOrgUser) => {
    const tags = await firestoreRepository.getAllTags(entityId, isOrgUser);
    return tags;
  };

  const fetchData = async () => {
    const [allPatients, allSessions, allTags] = await Promise.all([
      loadAllPatients(entityId.current, isOrgUser.current),
      loadAllSessions(entityId.current, isOrgUser.current),
      loadAllTags(entityId.current, isOrgUser.current),
    ]);
    const patientSessionsMap = allSessions.reduce((acc, session) => {
      if (!acc[session.patientGlobalId]) {
        acc[session.patientGlobalId] = [];
      }
      if (isOrgUser.current) {
        const owner = orgDataDetails.ownerData?.find(
          (owner) => owner.id === session.owner
        );
        const tech = orgDataDetails.techData?.find(
          (tech) => tech.id === session.technician
        );
        acc[session.patientGlobalId].push({
          ...session,
          ownerName: owner?.name || "Unassigned",
          techName: tech?.name || "Unassigned",
        });

        return acc;
      } else {
        acc[session.patientGlobalId].push({
          ...session,
        });
        return acc;
      }
    });

    const formattedData = allPatients
      .filter((patient) => patient.patientId) // Filter out patients with empty patientId
      .map((patient, index) => ({
        id: `patient-${index + 1}`,
        patientGlobalId: patient.globalId,
        patientName: `${patient.lastName || "Last Name"}, ${
          patient.firstName || "First Name"
        }`,
        firstName: patient.firstName,
        lastName: patient.lastName,
        patientId: patient.patientId || "Patient ID",
        birthDate: patient.dateOfBirth
          ? patient.dateOfBirth.toDate().toLocaleDateString("en-GB")
          : "DD/MM/YYYY",
        birthDateOriginal: patient.dateOfBirth
          ? patient.dateOfBirth.toDate()
          : null, // Preserve original Date object for sorting

        gender: genderMap[patient.genderIdx] || "Unknown",
        sessions: patientSessionsMap[patient.globalId] || [],
      }));
    setAllTags(allTags);
    setPatients(allPatients);
    setRows(formattedData);
    // sessionStorage.setItem("formattedData", JSON.stringify(formattedData));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = useMemo(() => {
    const userInputQuery = searchQuery.toLowerCase();

    // Function to check if a value should be excluded based on specific strings
    const isEmptyValue = (value) => {
      return (
        !value ||
        value === "Last Name, First Name" ||
        value === "DD/MM/YYYY" ||
        value === ""
      );
    };

    // If the search userInputQuery is empty, include all rows (no need to filter)
    if (userInputQuery === "") return rows;

    return rows.filter((row) => {
      // Check if the patient's name or birth date is "Last Name, First Name" or "DD/MM/YYYY"
      const isPatientNameEmpty = isEmptyValue(row.patientName);
      const isBirthDateEmpty = isEmptyValue(row.birthDate);

      // If the search userInputQuery matches "Last Name, First Name" or "DD/MM/YYYY",
      // ensure we exclude them unless the userInputQuery is exactly those values.
      if (
        (userInputQuery === "last name, first name" && isPatientNameEmpty) ||
        (userInputQuery === "dd/mm/yyyy" && isBirthDateEmpty)
      ) {
        return false;
      }

      // Check if any of the relevant fields match the search userInputQuery
      const matchesPatientName =
        row.patientName &&
        row.patientName.toLowerCase().includes(userInputQuery);
      const matchesPatientId =
        row.patientId && row.patientId.toLowerCase().includes(userInputQuery);
      const matchesBirthDate =
        row.birthDate && row.birthDate.toLowerCase().includes(userInputQuery);
      const matchesGender =
        row.gender && row.gender.toLowerCase().includes(userInputQuery);

      // Return true if any of the values match, otherwise false
      return (
        matchesPatientName ||
        matchesPatientId ||
        matchesBirthDate ||
        matchesGender
      );
    });
  }, [rows, searchQuery]);

  if (
    userData &&
    Object.keys(userData).length > 0 &&
    largestSubscriptionId === 0 &&
    isOrgUser &&
    isOrgUser.current === false
  ) {
    return (
      <Layout>
        <FreeUser></FreeUser>
      </Layout>
    );
  } else if (
    orgData &&
    Object.keys(orgData).length > 0 &&
    largestSubscriptionId === 0 &&
    isOrgUser &&
    isOrgUser.current
  ) {
    return (
      <Layout>
        <FreeOrg></FreeOrg>
      </Layout>
    );
  } else if (
    orgData &&
    largestSubscriptionId > 0 &&
    isOrgUser.current &&
    rdPermissions.current === 0
  ) {
    return (
      <Layout>
        <RestrictedDashboard></RestrictedDashboard>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={{ padding: "20px" }}>
        <>
          <div
            style={{
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            Patients
          </div>
          <div style={{ color: "#999999" }}>
            A view of the existing patients
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1em",
              marginTop: "0.5em",
            }}
          >
            <PatientCreation
              entityId={entityId.current}
              isOrgUser={isOrgUser.current}
              wrtPermissions={wrtPermissions.current}
              rdPermissions={rdPermissions.current}
              allPatients={patients}
              reloadCallback={() => setNeedReload(!needReload)}
            />
            <UploadPatientCSV
              entityId={entityId.current}
              isOrgUser={isOrgUser.current}
              patientIds={patients}
              reloadCallback={() => setNeedReload(!needReload)}
              wrtPermissions={wrtPermissions.current}
              rdPermissions={rdPermissions.current}
            />
          </div>
          <StyledTextField
            type="text"
            placeholder="Search "
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            style={{ marginLeft: "1em", marginBottom: "0.5em" }}
          />

          <div style={{ height: "70vh", width: "100%" }}>
            <PatientsTable
              rows={filteredRows}
              searchQuery={searchQuery}
              entityId={entityId.current}
              isOrgUser={isOrgUser.current}
              wrtPermissions={wrtPermissions.current}
              rdPermissions={rdPermissions.current}
              reloadCallback={() => setNeedReload(!needReload)}
              allTagsArray={allTags}
              orgDataDetails={orgDataDetails}
            />
          </div>
        </>
        <div></div>
      </div>
    </Layout>
  );
}

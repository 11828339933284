import React, { useContext } from "react";
import "../../style/dashboard.css";
import free from "./free.svg";
import { Grid } from "@mui/material";
import { UploadContext } from "../App";

const FreeUser = () => {
  const { isOrgUser, largestSubscriptionId } = useContext(UploadContext);

  return (
    <>
      {largestSubscriptionId === 0 && isOrgUser.current && (
        <Grid
          container
          style={{
            padding: "40px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={5}>
            <h1>No active subscription found</h1>
            <div style={{ fontSize: "22px" }}>
              Don't worry, your data is safe and waiting for you. Reactivate
              your subscription to regain full access and continue enjoying the
              benefits of MicroREC Connect.
            </div>
            <Grid item xs={12} marginTop={"2em"}>
              <a
                className="button_no_style contact_us_button"
                href="mailto:support@customsurgical.co"
              >
                Contact us
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            marginTop={{ xs: 5, md: 0 }}
            marginLeft={{ xs: 0, md: 5 }}
          >
            <img src={free} style={{ maxWidth: "100%" }}></img>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FreeUser;

import React, { useContext } from "react";
import devices from "../../images/dashboard/devices.png";
import "../../style/dashboard.css";
import freeUser from "../../images/dashboard/freeUser.png";
import example from "../../images/dashboard/image (1).png";
import storage from "../../images/dashboard/Upload (1).png";
import safe from "../../images/dashboard/Secure (1).png";
import organize from "../../images/dashboard/Organize (1).png";
import platform from "../../images/dashboard/Devices (1).png";
import Activate from "../subscription/activation";
import { UploadContext } from "../App";

const FreeUser = () => {
  const { userData } = useContext(UploadContext);

  return (
    <>
      {userData && (
        <>
          <div className="container">
            <div className="content-overlay">
              <div className="content">
                <h1 className="free_h1_text">DATA MANAGEMENT</h1>
                <h1 className="free_h1_text">Made easy</h1>
                <p style={{ fontSize: "20px" }}>
                  Your data is kept safe and secure, access it from{" "}
                  <b>any device, any time from anywhere.</b>
                </p>
                <div className="display_flex">
                  <a href="#subs" className="get_it_text">
                    Get it now
                  </a>
                  <Activate />
                </div>
              </div>
              <img src={devices} alt="Devices" className="max_width_40" />
            </div>
            <img src={freeUser} alt="Free User" className="freeuser_banner" />
          </div>

          <h1 className="h1_text">
            Focus on deliverying exceptional care while we take care of your
            data needs!
          </h1>
          <div className="flex_free_banner">
            <div className="flex_image">
              <img src={example} className="max_width_100"></img>
            </div>
            <div className="flex_text_block">
              <div className="flex_block">
                <div className="flex_sub_block">
                  <img className="width_40" src={storage}></img>
                  <h3>Upload and store videos and images</h3>
                  <div>Never worry about running out of phone storage.</div>
                </div>
                <div className="flex_sub_block">
                  <img className="width_40" src={safe}></img>
                  <h3>Keep your data secure</h3>
                  <div>
                    Keep your data safeguarded against any potential threats.
                  </div>
                </div>
              </div>
              <div className="flex_block">
                <div className="flex_sub_block">
                  <img className="width_40" src={organize}></img>
                  <h3>Manage and organize</h3>
                  <div>
                    Manage your media within a single, convenient platform.
                  </div>
                </div>
                <div className="flex_sub_block">
                  <img className="width_40" src={platform}></img>
                  <h3>Easily access</h3>
                  <div>
                    Access your data on any device, from anywhere, at any time.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="subs"></div>
          <div className="pricing_table_border">
            <div className="pricing_table_text">
              Unlock the ease of data management with MicroREC Connect.
            </div>
            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISH_KEY}
              client-reference-id={userData.uid}
              customer={userData.stripeCustomerId}
              customer-email={userData.email}
              customer-creation="if_required"
            ></stripe-pricing-table>
          </div>
        </>
      )}
    </>
  );
};

export default FreeUser;

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  MenuItem,
  Tooltip,
  Grid,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import ModeEditIcon from "../../images/dashboard/edit.svg";
import ModeEditIconGray from "../../images/dashboard/edit_gray.svg";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository from "../../repositories/firestoreRepository";
import { useLocation } from "react-router-dom";
import EditIcon from "../../images/gallery/edit.svg";
import Intercom from "@intercom/messenger-js-sdk";
import EditIconGray from "../../images/gallery/edit_gray.svg";
import "../dashboard/Dashboard.css";
import { AllOwners } from "../../utils/ownerUtils";
import { UploadContext } from "../App";
import { AllTechnicians } from "../../utils/technicianUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 10px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

const ModalEditSession = ({
  sessionObj,
  allPatients,
  patientName,
  allTagsArray,
  entityId,
  isOrgUser,
  reloadCallback,
  owner,
  tech,
  currentUser,
  wrtPermissions,
  rdPermissions,
}) => {
  if (!sessionObj) {
    console.error(
      "Error: no session object provided to ModalEditSession component."
    );
    sessionObj = {};
  }
  if (!sessionObj.tags) {
    sessionObj.tags = [];
  }
  if (!sessionObj.description) {
    sessionObj.description = "";
  }
  if (!patientName) {
    patientName = "";
  }
  if (!allTagsArray) {
    allTagsArray = [];
  }

  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [tagsSelected, setTagsSelected] = useState(sessionObj.tags);
  const [updatedPatientName, setUpdatedPatientName] = useState(patientName);
  const [updatedDescription, setUpdatedDescription] = useState(
    sessionObj.description
  );
  const [allTags, setAllTags] = useState(allTagsArray);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState();
  const { selectedOwner } = useContext(UploadContext);
  const { selectedTechnician } = useContext(UploadContext);

  useEffect(() => {
    const fetchData = async () => {
      if (rdPermissions && rdPermissions !== 0) {
        const patientsData = await firestoreRepository.getAllPatients(
          entityId,
          isOrgUser
        );
        var tempPatients = {};
        patientsData.forEach((patient) => {
          const globalId = patient.globalId;
          const canonicalId = patient.canonicalPatientId;
          tempPatients[globalId] = canonicalId;
        });
        setPatientIdDuplicate(tempPatients);
      }
    };
    fetchData();
  }, []);

  const handleTextareaChangeDesc = (e) => {
    setUpdatedDescription(e.target.value);
  };

  async function updateAllFields() {
    // First we handle the update to the patientId.
    // If the new canonicalPatientId is already in the database, we update the session with the existing patientGlobalId.
    // If the new canonicalPatientId is not in the database, we create a new patient entry and update the session with the new patientGlobalId.
    const canonicalId = getCanonicalPatientId(updatedPatientName);
    const duplicatedPatient = Object.entries(patientIdDuplicate).find(
      ([_, value]) => value === canonicalId
    );
    if (duplicatedPatient) {
      const [duplicatedPatientGlobalId, _] = duplicatedPatient;
      const sessionData = {
        patientGlobalId: duplicatedPatientGlobalId,
        description: updatedDescription,
        tags: tagsSelected,
        owner: selectedOwner,
        technician: selectedTechnician,
      };
      firestoreRepository.updateSession(
        entityId,
        isOrgUser,
        sessionObj.globalId,
        sessionData,
        false
      );
      firestoreRepository.updatePatient(
        entityId,
        isOrgUser,
        duplicatedPatientGlobalId,
        updatedPatientName,
        true
      );
    } else {
      // Create a new patient entry
      const newPatientGlobalId = await firestoreRepository.createPatient(
        entityId,
        isOrgUser,
        updatedPatientName,
        false
      );

      const sessionData = {
        patientGlobalId: newPatientGlobalId,
        description: updatedDescription,
        tags: tagsSelected,
        owner: selectedOwner,
        technician: selectedTechnician,
      };
      firestoreRepository.updateSession(
        entityId,
        isOrgUser,
        sessionObj.globalId,
        sessionData,
        true
      );
    }

    window.Intercom("trackEvent", "session_edited", {
      created_at: Math.floor(Date.now() / 1000),
      patient_id: updatedPatientName.length > 0 ? true : false,
      tag: tagsSelected.length > 0 ? true : false,
      description: updatedDescription.length > 0 ? true : false,
      session_id: sessionObj.globalId,
    });

    reloadCallback(); // Reload the data
    handleClose(); // Close the modal
  }

  const location = useLocation();
  const isSessionUrl = location.pathname.includes("sessions");

  function handleEditSession() {
    setOpen(true);
  }

  return (
    <>
      <div>
        {isSessionUrl ? (
          (isOrgUser && wrtPermissions === 0) ||
          (isOrgUser && currentUser !== owner && wrtPermissions === 1) ? (
            <Tooltip title="You don't have the required permissions to edit this session">
              <button className="button_no_style edit_session_button">
                <img src={EditIconGray} className="edit_session_icon" />
              </button>
            </Tooltip>
          ) : (
            <button
              className="button_no_style edit_session_button"
              onClick={() => handleEditSession()}
            >
              <img src={EditIcon} className="edit_session_icon" />
            </button>
          )
        ) : wrtPermissions === 0 ||
          (currentUser !== owner && wrtPermissions === 1) ? (
          <Tooltip title="You don't have the required permissions to edit this session">
            <MenuItem
              style={{
                display: "flex",
                borderTop: "1px solid #C4C4C4",
                justifyContent: "space-between",
              }}
            >
              <div
                className="menu_edit_button_text"
                style={{ color: "#999999" }}
              >
                Edit
              </div>
              <img src={ModeEditIconGray} className="menu_edit_button_icon" />
            </MenuItem>
          </Tooltip>
        ) : (
          <MenuItem
            style={{
              display: "flex",
              borderTop: "1px solid #C4C4C4",
              justifyContent: "space-between",
            }}
            onClick={() => {
              handleEditSession();
            }}
          >
            <div className="menu_edit_button_text">Edit</div>
            <img src={ModeEditIcon} className="menu_edit_button_icon" />
          </MenuItem>
        )}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Edit Session
                    </div>
                  </div>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      marginTop: "1em",
                      padding: "30px",
                    }}
                  >
                    <Grid item xs={12} sm={5.8}>
                      <label className="label">Patient Id:</label>
                      <Autocomplete
                        options={allPatients}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.patientId || "";
                        }}
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "2em",
                        }}
                        onChange={(event, newValue) => {
                          if (
                            typeof newValue === "string" &&
                            newValue.startsWith("Create Patient: ")
                          ) {
                            const patientId = newValue.slice(16);
                            setUpdatedPatientName(patientId);
                          } else if (
                            newValue &&
                            typeof newValue === "object" &&
                            newValue.patientId
                          ) {
                            setUpdatedPatientName(newValue.patientId);
                          } else {
                            setUpdatedPatientName("");
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setUpdatedPatientName(newInputValue);
                        }}
                        value={
                          allPatients?.find(
                            (option) => option.patientId === updatedPatientName
                          ) || { patientId: updatedPatientName }
                        }
                        renderInput={(params) => (
                          <StyledTextField
                            label={
                              patientName.length > 0 ? "" : "Add the Patient ID"
                            }
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {typeof option === "string"
                              ? option
                              : option.patientId}
                          </li>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = options.filter((option) => {
                            return (
                              typeof option === "object" &&
                              option.patientId &&
                              option.patientId
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            );
                          });

                          if (
                            params.inputValue !== "" &&
                            !filtered.some(
                              (option) =>
                                typeof option === "object" &&
                                option.patientId.toLowerCase() ===
                                  params.inputValue.toLowerCase()
                            )
                          ) {
                            filtered.push(
                              `Create Patient: ${params.inputValue}`
                            );
                          }

                          const selectedOptions = Array.isArray(params.value)
                            ? params.value.map((option) => option)
                            : [];

                          const combinedOptions = [
                            ...new Set([...selectedOptions, ...filtered]),
                          ];

                          return combinedOptions.slice(0, 5);
                        }}
                      />
                      <label className="label">Date:</label>
                      <div>
                        <Tooltip title="You cannot change the date">
                          <StyledTextField
                            label={sessionObj.createdAt
                              ?.toDate()
                              .toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false,
                              })}
                            style={{
                              width: "100%",
                              resize: "none",
                              display: "flex",
                              marginTop: "1em",
                              marginBottom: "2em",
                            }}
                            disabled={true}
                          />
                        </Tooltip>
                      </div>
                      <label className="label">Tags:</label>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "104px",
                          overflowY: "auto",
                        }}
                        className="margin_top_wrap"
                      >
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          style={{ width: "100%" }}
                          options={allTags.map((option) => option)}
                          freeSolo
                          value={tagsSelected}
                          filterOptions={(options, params) => {
                            const filtered = options.filter((option) => {
                              return option
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase());
                            });
                            if (
                              params.inputValue !== "" &&
                              !filtered.some(
                                (option) =>
                                  option.toLowerCase() ===
                                  params.inputValue.toLowerCase()
                              )
                            ) {
                              filtered.push(`Create tag: ${params.inputValue}`);
                            }

                            const selectedOptions = Array.isArray(params.value)
                              ? params.value.map((option) => option)
                              : [];
                            const combinedOptions = [
                              ...new Set([...selectedOptions, ...filtered]),
                            ];

                            return combinedOptions.slice(0, 5);
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              const displayValue = option.startsWith(
                                "Create tag: "
                              )
                                ? option.slice(12)
                                : option;
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Chip
                                  variant="outlined"
                                  label={displayValue}
                                  key={key}
                                  {...tagProps}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              label={
                                tagsSelected?.length < 1 ? "Add a tag" : ""
                              }
                            />
                          )}
                          onChange={(event, newValue) => {
                            const finalTags = newValue.map((tag) => {
                              if (tag.startsWith("Create tag: ")) {
                                const newTag = tag.slice(12);
                                window.Intercom(
                                  "trackEvent",
                                  "new_tag_created",
                                  {
                                    created_at: Math.floor(Date.now() / 1000),
                                    tag: newTag,
                                  }
                                );
                                return newTag;
                              } else {
                                return tag;
                              }
                            });
                            setTagsSelected(finalTags);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {isOrgUser === true ? (
                        <>
                          <label className="label">Clinician:</label>
                          <AllOwners
                            sessionOwner={owner}
                            wrtPermissions={wrtPermissions}
                          />
                          <label className="label">Technician:</label>
                          <AllTechnicians
                            sessionTech={tech}
                            wrtPermissions={wrtPermissions}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      <label className="label">Comment:</label>

                      <div style={{ flexGrow: "1", height: "100%" }}>
                        <StyledTextField
                          onChange={handleTextareaChangeDesc}
                          value={updatedDescription}
                          label={
                            updatedDescription.length > 0
                              ? ""
                              : "A comment or note about a patient, photo or video description"
                          }
                          multiline
                          rows={isOrgUser === true ? 2.5 : 11.5}
                          style={{
                            width: "100%",
                            resize: "none",
                            display: "flex",
                            marginTop: "1em",
                            marginBottom: "2em",
                          }}
                          InputProps={{
                            style: {
                              height: "100%",
                              resize: "none",
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{
                        marginTop: "1em",
                        width: "60%",
                        backgroundColor: "#8F4FFF",
                        padding: "15px 20px",
                      }}
                      className="button"
                      onClick={updateAllFields}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalEditSession;

import React, { useContext } from "react";
import "../../style/dashboard.css";
import ModalCreateSession from "../common/ModalSessionCreation";
import devices from "../../images/dashboard/empty_devices.svg";
import qr from "../../images/dashboard/qr.svg";
import { UploadContext } from "../App";
import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

const EmptyDashboard = ({ wrtPermissions }) => {
  const { entityId, isOrgUser } = useContext(UploadContext);

  return (
    <>
      <div className="empty_sessions_div">
        <img src={devices} className="width_320"></img>
        <div className="empty_h1">Ready to begin your Connect experience?</div>
        <div className="new_session_div">
          <div className="margin_right_1 empty_text">Click on </div>
          {/* {entityId.current && (
            <ModalCreateSession
              allPatients={[]}
              allTagsArray={[]}
              entityId={entityId.current}
              isOrgUser={isOrgUser.current}
              wrtPermissions={wrtPermissions}
              checkedOwner={[]}
            />
          )} */}
          <div>
            {wrtPermissions === 0 ? (
              <Tooltip
                title={
                  wrtPermissions === 0
                    ? "You don't have the required permissions to create a new session"
                    : ""
                }
              >
                <button
                  className="gray_new_session button_no_style "
                  style={{ marginTop: "0" }}
                >
                  <div className="centered_items_flex_div">
                    <IconButton
                      type="button"
                      sx={{ p: "10px", color: "#999999" }}
                      aria-label="search"
                    >
                      <Add />
                    </IconButton>
                    <div className="inactive_session">New Session</div>
                  </div>
                </button>
              </Tooltip>
            ) : (
              entityId &&
              entityId.current && (
                <ModalCreateSession
                  allPatients={[]}
                  allTagsArray={[]}
                  entityId={entityId.current}
                  isOrgUser={isOrgUser.current}
                  wrtPermissions={wrtPermissions}
                  checkedOwner={[]}
                />
              )
            )}
          </div>
          <div className="empty_text">to start</div>
        </div>
        <div className="qr_text">
          Scan the QR code to get the MicroREC app and complete your setup:{" "}
        </div>
        <img src={qr} className="margin_top_1_qr"></img>
      </div>
    </>
  );
};

export default EmptyDashboard;

import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../Layout";
import { CircularProgress, Grid, Tooltip } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import FreeOrg from "./free_org";
import info from "./info.svg";
import "../dashboard/Dashboard.css";
import { UploadContext } from "../App";

const Users = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { orgData, largestSubscriptionId, isOrgUser } =
    useContext(UploadContext);

  useEffect(() => {
    if (orgData === null || largestSubscriptionId === 0) return;
    const usersData = Object.entries(orgData.users).map(([uid, user]) => {
      const mapPermissionToText = (permission) => {
        switch (permission) {
          case 0:
            return "NONE";
          case 1:
            return "SELF";
          case 2:
            return "ALL";
          default:
            return "";
        }
      };

      const mapViewPermissionToText = (permission) => {
        switch (permission) {
          case "SELF":
            return "User can only see their data";
          case "ALL":
            return "User can see the data for the whole organization";
          default:
            return "";
        }
      };

      const mapEditPermissionToText = (permission) => {
        switch (permission) {
          case "SELF":
            return "User can only edit their data";
          case "ALL":
            return "User can edit the data for the whole organization";
          default:
            return "";
        }
      };

      const mapDeletePermissionToText = (permission) => {
        switch (permission) {
          case "NONE":
            return "User cannot delete anyone's data.";
          case "SELF":
            return "User can only delete their data.";
          case "ALL":
            return "User can delete data from anyone in the organization.";
          default:
            return "";
        }
      };

      const mapSharePermissionToText = (permission) => {
        switch (permission) {
          case "NONE":
            return "User cannot share anyone's data.";
          case "SELF":
            return "User can only share their data.";
          case "ALL":
            return "User can share data from anyone in the organization.";
          default:
            return "";
        }
      };

      const pDltText = mapPermissionToText(user.pDlt);
      const pShrText = mapPermissionToText(user.pShr);
      const pRdText = mapPermissionToText(user.pRd);
      const pWrtText = mapPermissionToText(user.pWrt);

      const deleteTooltipText = mapDeletePermissionToText(pDltText);
      const shareTooltipText = mapSharePermissionToText(pShrText);
      const readTooltipText = mapViewPermissionToText(pRdText);
      const writeTooltipText = mapEditPermissionToText(pWrtText);
      return {
        name: user.name,
        email: user.email,
        clrIdx: user.clrIdx,
        pDlt: user.pDlt,
        pShr: user.pShr,
        pRd: user.pRd,
        pWrt: user.pWrt,
        roleId: user.roleId,
        uid: uid,
        isAdmin: user.isAdmin,
        pDltText,
        pShrText,
        pRdText,
        pWrtText,
        deleteTooltipText,
        shareTooltipText,
        readTooltipText,
        writeTooltipText,
      };
    });
    setUserData(usersData);
    setIsLoading(false);
  }, [currentUser.uid, orgData]);

  function getColorByClrIdx(clrIdx) {
    const colorMap = {
      0: "#F0F0F2",
      1: "#BAA2D6",
      2: "#64BAAA",
      3: "#F2B56B",
      4: "#F1E7FF",
      5: "#C4FCF0",
      6: "#F2DEC4",
      7: "#8A6BBF",
      8: "#4C8076",
      9: "#F28705",
      10: "#8F4FFF",
      11: "#00C5B7",
      12: "#FFA100",
    };

    return colorMap[clrIdx] || "#F0F0F2";
  }

  if (
    orgData &&
    Object.keys(orgData).length > 0 &&
    largestSubscriptionId === 0 &&
    isOrgUser.current
  ) {
    return (
      <Layout>
        <FreeOrg></FreeOrg>
      </Layout>
    );
  }

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{
                width: "100%",
              }}
            >
              <span className="users_span">Users </span>
              <span className="users_span users_text_color">
                for {orgData.name}
              </span>
              <div className="table_container">
                <Grid
                  container
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#E6D8FF",
                    textAlign: "center",
                    fontWeight: "600",
                    justifyContent: "space-between",
                    marginTop: "2em",
                    padding: "15px 20px",
                  }}
                >
                  <Grid item xs={2.5}>
                    NAME
                  </Grid>
                  <Grid item xs={2.5}>
                    EMAIL
                  </Grid>
                  <Grid item xs={2}>
                    ROLE
                  </Grid>
                  <Grid item xs={1}>
                    VIEW
                  </Grid>
                  <Grid item xs={1}>
                    EDIT
                  </Grid>
                  <Grid item xs={1}>
                    DELETE
                  </Grid>
                  <Grid item xs={1}>
                    SHARE
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    textAlign: "center",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    border: "1px solid #EFEFEFEF",
                    marginTop: "1em",
                  }}
                >
                  {userData
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((user, uid) => (
                      <Grid
                        key={uid}
                        container
                        style={{
                          width: "100%",
                          borderBottom:
                            uid === userData.length - 1
                              ? "none"
                              : "1px solid #EFEFEFEF",
                          justifyContent: "space-between",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          item
                          xs={2.5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <div
                            className="users_icon"
                            style={{
                              backgroundColor: getColorByClrIdx(user.clrIdx),
                            }}
                          >
                            {user.name
                              .split(" ")
                              .map((name) => name[0])
                              .join("")}
                          </div>
                          <span className="margin_left_10">{user.name}</span>
                        </Grid>
                        <Grid
                          item
                          xs={2.5}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {user.email}
                        </Grid>
                        <Grid item xs={2}>
                          <div>
                            {user.isAdmin === true
                              ? "Admin"
                              : user.roleId === 0
                              ? "Technician"
                              : user.roleId === 1
                              ? "Clinician"
                              : "Unassigned"}
                          </div>
                        </Grid>
                        <Tooltip title={user.readTooltipText}>
                          <Grid
                            item
                            xs={1}
                            style={{
                              textAlign: "center",
                              padding: "5px",
                              backgroundColor: "#F0F0F2",
                              borderRadius: "10px",
                            }}
                          >
                            {user.pRdText}
                          </Grid>
                        </Tooltip>
                        <Tooltip title={user.writeTooltipText}>
                          <Grid
                            item
                            xs={1}
                            style={{
                              textAlign: "center",
                              padding: "5px",
                              backgroundColor: "#F0F0F2",
                              borderRadius: "10px",
                            }}
                          >
                            {user.pWrtText}
                          </Grid>
                        </Tooltip>
                        <Tooltip title={user.deleteTooltipText}>
                          <Grid
                            item
                            xs={1}
                            style={{
                              textAlign: "center",
                              padding: "5px",
                              backgroundColor: "#F0F0F2",
                              borderRadius: "10px",
                            }}
                          >
                            {user.pDltText}
                          </Grid>
                        </Tooltip>
                        <Tooltip title={user.shareTooltipText}>
                          <Grid
                            item
                            xs={1}
                            style={{
                              textAlign: "center",
                              padding: "5px",
                              backgroundColor: "#F0F0F2",
                              borderRadius: "10px",
                            }}
                          >
                            {user.pShrText}
                          </Grid>
                        </Tooltip>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            xs={11}
            justifyContent={{ xs: "space-between", md: "center" }}
            style={{
              backgroundColor: "#E6D8FF",
              padding: "15px",
              borderRadius: "10px",
              marginTop: "2em",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "2em",
            }}
          >
            <Grid item xs={1} md={1}>
              <img src={info}></img>
            </Grid>
            <Grid item xs={9} md={7}>
              <div>
                <div className="users_permissions_div">
                  Do you need to add someone to the account or change someone's
                  permissions?
                </div>
                <div>
                  If you want to add someone to the team, or would like to edit
                  the permission of some of your users, please contact us at
                  support@customsurgical.co
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              marginTop={{ xs: "1em", md: "0" }}
            >
              <a
                className="button_no_style contact_us_button"
                href="mailto:support@customsurgical.co"
              >
                Contact us
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default Users;

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  SortDirection,
  Table,
} from "react-virtualized";
import ModalEditPatient from "../common/ModalEditPatient";
import "../../style/patient.css";
import "../../style/modal.css";
import ModalCreateSession from "../common/ModalSessionCreation";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CircularProgress, stepButtonClasses } from "@mui/material";
import firestoreRepository, {
  queryPatientsDataByQueryIdx,
} from "../../repositories/firestoreRepository";

const VirtualizedPatientTable = ({
  rows,
  searchQuery,
  entityId,
  isOrgUser,
  wrtPermissions,
  rdPermissions,
  allTagsArray,
  orgDataDetails,
}) => {
  const [orderBy, setOrderBy] = useState({
    sortBy: "canonicalPatientId",
    sortDirection: SortDirection.ASC,
  });
  const [orderBySession, setOrderBySession] = useState({
    sortBy: "createdAt",
    sortDirection: SortDirection.DESC,
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedRowHeight, setExpandedRowHeight] = useState(0);
  const [preventCollapse, setPreventCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortedRows, setSortedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [needReload, setNeedReload] = useState(false);
  const [allPatients, setAllPatients] = useState([]);

  const tableRef = useRef();
  const sessionTableRef = useRef();
  const expandedContentRef = useRef();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("formattedData");
      sessionStorage.removeItem("orderBy");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (
      entityId === null ||
      isOrgUser === null ||
      (isOrgUser && rdPermissions === undefined)
    ) {
      return;
    }

    if (isOrgUser && rdPermissions === 0) {
      setIsLoading(false);
      return;
    } else if (entityId !== null && isOrgUser !== null) {
      setSearchTerm(searchQuery);
      fetchSortedData();
    }
  }, [
    searchQuery,
    needReload,
    rows,
    entityId,
    isOrgUser,
    orderBy.sortBy,
    orderBy.sortDirection,
  ]);

  useEffect(() => {
    const storedFormattedRows = JSON.parse(
      sessionStorage.getItem("formattedData")
    );
    const storedOrderBy = JSON.parse(sessionStorage.getItem("orderBy"));
    if (storedOrderBy) {
      setOrderBy(storedOrderBy);
    }
    if (
      rdPermissions !== undefined ||
      rdPermissions !== 0 ||
      rdPermissions !== null
    ) {
      setIsLoading(false);
      return;
    } else if (storedFormattedRows && storedFormattedRows.length > 0) {
      setSortedRows(storedFormattedRows);
    } else if (entityId !== null && isOrgUser !== null) {
      fetchSortedData();
    }
  }, [isOrgUser, entityId]);

  const genderMap = {
    0: "",
    1: "Male",
    2: "Female",
    9: "Other",
  };

  const formatDate = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const handleRequestSort = ({ sortBy, sortDirection }) => {
    const newOrderBy = {
      sortBy: sortBy === "patientId" ? "canonicalPatientId" : sortBy,
      sortDirection,
    };
    setOrderBy(newOrderBy);
    sessionStorage.setItem("orderBy", JSON.stringify(newOrderBy));
    setExpandedRow(null);
  };

  // Custom cell renderer for Patient ID
  const patientIdCellRenderer = ({ rowData }) => {
    return rowData.patientId;
  };

  const handleSessionSort = ({ sortBy, sortDirection }) => {
    setOrderBySession({ sortBy, sortDirection });
  };

  const getQueryIdx = (sortBy) => {
    switch (sortBy) {
      case "canonicalPatientId":
        return 0;
      case "birthDate":
        return 1;
      case "gender":
        return 2;
      case "patientName":
        return 3;
      default:
        return 0; // Default to sorting by name
    }
  };

  const filteredRows = useMemo(() => {
    if (searchTerm === "") return sortedRows;
    else {
      const lowerSearch = searchTerm.toLowerCase();
      return sortedRows.filter(
        (patient) =>
          patient.patientName.toLowerCase().includes(lowerSearch) ||
          patient.patientId.toLowerCase().includes(lowerSearch)
      );
    }
  }, [searchTerm, sortedRows]);

  const loadAllSessions = async (entityId, isOrgUser) => {
    const sessionsData = await firestoreRepository.getAllSessions(
      entityId,
      isOrgUser
    );
    return sessionsData;
  };

  const fetchSortedData = async () => {
    const allSessions = await loadAllSessions(entityId, isOrgUser);
    const allPatients = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    const patientSessionsMap = allSessions.reduce((acc, session) => {
      if (!acc[session.patientGlobalId]) {
        acc[session.patientGlobalId] = [];
      }
      if (isOrgUser) {
        const owner = orgDataDetails.ownerData?.find(
          (owner) => owner.id === session.owner
        );
        const tech = orgDataDetails.techData?.find(
          (tech) => tech.id === session.technician
        );
        acc[session.patientGlobalId].push({
          ...session,
          ownerName: owner?.name || "Unassigned",
          techName: tech?.name || "Unassigned",
        });

        return acc;
      } else {
        acc[session.patientGlobalId].push({
          ...session,
        });
        return acc;
      }
    });
    const order = orderBy.sortDirection === SortDirection.ASC ? "asc" : "desc";
    const queryIdx = getQueryIdx(orderBy.sortBy);
    const sortedData = await queryPatientsDataByQueryIdx(
      entityId,
      isOrgUser,
      queryIdx,
      order
    );

    // setIsLoading(false); // Set isLoading to false after sorting
    const formattedSortData = sortedData
      .filter((patient) => patient.patientId) // Filter out patients with empty patientId
      .map((patient, index) => ({
        id: `patient-${index + 1}`,
        patientGlobalId: patient.globalId,
        patientName: `${patient.lastName || ""}, ${patient.firstName || ""}`,
        firstName: patient.firstName,
        lastName: patient.lastName,
        patientId: patient.patientId || "",
        canonicalPatientId: patient.canonicalPatientId || "",
        birthDate: patient.dateOfBirth
          ? patient.dateOfBirth.toDate().toLocaleDateString("en-GB")
          : "",
        birthDateOriginal: patient.dateOfBirth
          ? patient.dateOfBirth.toDate()
          : null, // Preserve original Date object for sorting
        gender: genderMap[patient.genderIdx] || "",
        sessions: patientSessionsMap[patient.globalId] || [],
      }));
    setAllPatients(allPatients);
    setSortedRows(formattedSortData);
    sessionStorage.setItem("orderBy", JSON.stringify(orderBy));
    sessionStorage.setItem("formattedData", JSON.stringify(formattedSortData));
  };

  const toggleRow = useCallback(
    (index) => {
      setExpandedRow(expandedRow === index ? null : index);
    },
    [expandedRow]
  );

  const sortedSessions = useMemo(() => {
    if (expandedRow === null || !sortedRows[expandedRow]?.sessions) return [];
    const sessions = sortedRows[expandedRow].sessions;
    return sessions.sort((a, b) => {
      const isAsc = orderBySession.sortDirection === SortDirection.ASC;
      const aValue = a[orderBySession.sortBy];
      const bValue = b[orderBySession.sortBy];

      if (
        Array.isArray(aValue) &&
        aValue.length === 0 &&
        Array.isArray(bValue) &&
        bValue.length > 0
      )
        return 1;
      if (
        Array.isArray(aValue) &&
        aValue.length > 0 &&
        Array.isArray(bValue) &&
        bValue.length === 0
      )
        return -1;
      if (
        Array.isArray(aValue) &&
        aValue.length === 0 &&
        Array.isArray(bValue) &&
        bValue.length === 0
      )
        return 0;

      if (aValue === "Unassigned" && bValue !== "Unassigned") return 1;
      if (aValue !== "Unassigned" && bValue === "Unassigned") return -1;
      if (aValue === "Unassigned" && bValue === "Unassigned") return 0;

      if (!aValue) return 1;
      if (!bValue) return -1;

      const parseDate = (value) => {
        if (typeof value !== "string") return null;
        const [datePart, timePart] = value.split(" ");
        if (!datePart || !timePart) return null;
        const [day, month, year] = datePart.split("/").map(Number);
        const [hours, minutes] = timePart.split(":").map(Number);
        const date = new Date(year, month - 1, day, hours, minutes);
        return isNaN(date.getTime()) ? null : date.getTime();
      };

      const aDate = parseDate(aValue);
      const bDate = parseDate(bValue);

      if (aDate !== null && bDate !== null) {
        return isAsc ? aDate - bDate : bDate - aDate;
      }

      if (aDate === null && bDate !== null) return 1;
      if (aDate !== null && bDate === null) return -1;

      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  }, [expandedRow, orderBySession]); // No need to depend on 'rows' here

  useEffect(() => {
    if (expandedRow !== null && expandedContentRef.current) {
      const rowHeight = 50;
      const headerHeight = 50;
      const minimumHeight = 90; // Fallback minimum height

      const calculatedHeight = Math.max(
        sortedRows[expandedRow].sessions.length * rowHeight + headerHeight,
        minimumHeight
      );
      setExpandedRowHeight(calculatedHeight);
    }
    tableRef.current?.recomputeRowHeights();
  }, [expandedRow]);

  useEffect(() => {
    if (!preventCollapse) {
      setExpandedRow(null); // Collapse the expanded row when rows change
    }
    setPreventCollapse(false); // Reset preventCollapse
  }, [sortedRows, rows]);

  const getRowHeight = ({ index }) => {
    if (index === expandedRow) {
      const hasSessions = sortedRows[index]?.sessions?.length > 0;
      return expandedRowHeight + (hasSessions ? 150 : 70);
    }
    return 70;
  };

  const sessionRenderer = ({ index, key, style, className, columns }) => {
    const session = sortedSessions[index];
    return (
      <Link
        to={`/sessions/${session.globalId}`}
        key={key}
        style={{
          ...style,
          display: "flex",
          textDecoration: "none",
          color: "inherit",
        }}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {columns}
      </Link>
    );
  };

  const rowRenderer = (props) => {
    const { index, style, className, key, rowData } = props;

    if (index === expandedRow) {
      return (
        <div
          key={key}
          style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className={className}
        >
          {defaultTableRowRenderer({
            ...props,
            style: {
              height: 70,
              width: "100%",
              backgroundColor:
                expandedRow === index ? "#f1e7ff" : "transparent",
            },
          })}
          <div ref={expandedContentRef} style={{ width: "100%" }}>
            {sortedRows && sortedRows[expandedRow]?.sessions?.length > 0 ? (
              <div>
                <>
                  {isOrgUser ? (
                    <>
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <Table
                            ref={sessionTableRef}
                            width={width}
                            height={expandedRowHeight} // Calculate height dynamically
                            headerHeight={50}
                            rowHeight={50}
                            rowCount={sortedSessions.length}
                            rowGetter={({ index }) => sortedSessions[index]}
                            sort={handleSessionSort}
                            sortBy={orderBySession.sortBy}
                            sortDirection={orderBySession.sortDirection}
                            rowRenderer={sessionRenderer} // Add rowRenderer here
                            rowStyle={{
                              borderRadius: "0",
                            }}
                            rowClassName="tableSessionHover"
                          >
                            <Column
                              label="Examination Date"
                              dataKey="createdAt"
                              width={width * 0.2}
                              cellRenderer={({ cellData }) =>
                                formatDate(cellData)
                              }
                              headerRenderer={headerRenderer}
                              headerClassName="sessionTableHeader"
                            />
                            <Column
                              label="Description"
                              dataKey="description"
                              width={width * 0.2}
                              cellRenderer={({ cellData }) =>
                                cellData || (
                                  <span style={{ color: "#999999" }}>
                                    No description
                                  </span>
                                )
                              }
                              headerRenderer={headerRenderer}
                            />
                            <Column
                              label="Tags"
                              dataKey="tags"
                              width={width * 0.2}
                              cellRenderer={({ cellData }) =>
                                cellData.length > 0 ? (
                                  cellData.join(", ")
                                ) : (
                                  <span style={{ color: "#999999" }}>
                                    No tags
                                  </span>
                                )
                              }
                              headerRenderer={headerRenderer}
                            />

                            <Column
                              label="Clinician"
                              dataKey="ownerName"
                              width={width * 0.2}
                              cellRenderer={({ cellData }) =>
                                cellData === "Unassigned" ? (
                                  <span style={{ color: "#999999" }}>
                                    {cellData}
                                  </span>
                                ) : (
                                  `Dr. ${cellData}`
                                )
                              }
                              headerRenderer={headerRenderer}
                            />
                            <Column
                              label="Technician"
                              dataKey="techName"
                              width={width * 0.2}
                              cellRenderer={({ cellData }) =>
                                cellData === "Unassigned" ? (
                                  <span style={{ color: "#999999" }}>
                                    {cellData}
                                  </span>
                                ) : (
                                  cellData
                                )
                              }
                              headerRenderer={headerRenderer}
                            />
                          </Table>
                        )}
                      </AutoSizer>
                    </>
                  ) : (
                    <>
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <Table
                            ref={sessionTableRef}
                            width={width}
                            height={expandedRowHeight} // Calculate height dynamically
                            headerHeight={50}
                            rowHeight={50}
                            rowCount={sortedSessions.length}
                            rowGetter={({ index }) => sortedSessions[index]}
                            sort={handleSessionSort}
                            sortBy={orderBySession.sortBy}
                            sortDirection={orderBySession.sortDirection}
                            rowRenderer={sessionRenderer} // Add rowRenderer here
                            rowStyle={{
                              borderRadius: "0",
                            }}
                            rowClassName="tableSessionHover"
                          >
                            <Column
                              label="Examination Date"
                              dataKey="createdAt"
                              width={width * 0.4}
                              cellRenderer={({ cellData }) =>
                                formatDate(cellData)
                              }
                              headerRenderer={headerRenderer}
                              headerClassName="sessionTableHeader"
                            />
                            <Column
                              label="Description"
                              dataKey="description"
                              width={width * 0.4}
                              cellRenderer={({ cellData }) =>
                                cellData || (
                                  <span style={{ color: "#999999" }}>
                                    No description
                                  </span>
                                )
                              }
                              headerRenderer={headerRenderer}
                            />
                            <Column
                              label="Tags"
                              dataKey="tags"
                              width={width * 0.4}
                              cellRenderer={({ cellData }) =>
                                cellData.length > 0 ? (
                                  cellData.join(", ")
                                ) : (
                                  <span style={{ color: "#999999" }}>
                                    No tags
                                  </span>
                                )
                              }
                              headerRenderer={headerRenderer}
                            />
                          </Table>
                        )}
                      </AutoSizer>
                    </>
                  )}
                </>

                <div
                  style={{
                    borderBottom: "1px solid var(--divider, #0000001f)",
                  }}
                ></div>
                <div
                  style={{ padding: "10px 0" }}
                  onClick={(e) => {
                    setPreventCollapse(true);
                  }}
                >
                  <ModalCreateSession
                    allPatients={allPatients}
                    allTagsArray={allTagsArray}
                    entityId={entityId}
                    isOrgUser={isOrgUser}
                    wrtPermissions={wrtPermissions}
                    rdPermissions={rdPermissions}
                    selectedPatient={rowData.patientId}
                    reloadCallback={() => setNeedReload(!needReload)}
                    onClose={() => setPreventCollapse(false)} // Update preventCollapse when modal closes
                  />
                </div>
              </div>
            ) : (
              <>
                <div style={{ padding: "10px", color: "#999999" }}>
                  This patient contains no sessions
                </div>
                <div
                  style={{
                    borderBottom: "1px solid var(--divider, #0000001f)",
                  }}
                ></div>
                <div
                  style={{ padding: "10px 0" }}
                  onClick={(e) => {
                    setPreventCollapse(true);
                  }}
                >
                  <ModalCreateSession
                    allPatients={allPatients}
                    allTagsArray={allTagsArray}
                    entityId={entityId}
                    isOrgUser={isOrgUser}
                    wrtPermissions={wrtPermissions}
                    rdPermissions={rdPermissions}
                    selectedPatient={rowData.patientId}
                    reloadCallback={() => setNeedReload(!needReload)}
                    onClose={() => setPreventCollapse(false)} // Update preventCollapse when modal closes
                  />
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
    return defaultTableRowRenderer(props);
  };

  const headerRenderer = ({ dataKey, label, sortBy, sortDirection }) => {
    const isSorted = sortBy === dataKey;
    const arrow = isSorted ? (
      sortDirection === SortDirection.ASC ? (
        <ArrowDownward style={{ paddingLeft: "8px" }} />
      ) : (
        <ArrowUpward style={{ paddingLeft: "8px" }} />
      )
    ) : (
      ""
    );
    return (
      <div className="header-cell">
        {label} {arrow}
      </div>
    );
  };

  const expandCollapseRenderer = ({ rowIndex }) => (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => toggleRow(rowIndex)}
    >
      {expandedRow === rowIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </div>
  );

  const cellRenderer = (rowData) => (
    <div
      onClick={(e) => {
        setPreventCollapse(true);
      }}
    >
      <ModalEditPatient
        entityId={entityId}
        isOrgUser={isOrgUser}
        wrtPermissions={wrtPermissions}
        rdPermissions={rdPermissions}
        firstNameExist={rowData.firstName}
        lastNameExist={rowData.lastName}
        patientIdExist={rowData.patientId}
        birthDateExist={rowData.birthDate}
        genderExist={rowData.gender}
        reloadCallback={() => setNeedReload(!needReload)}
        allPatients={allPatients}
        patientGlobalId={rowData.patientGlobalId}
        onClose={() => setPreventCollapse(false)} // Update preventCollapse when modal closes
      />
    </div>
  );

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {isLoading || sortedRows.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              ref={tableRef}
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={getRowHeight}
              rowCount={filteredRows.length}
              rowGetter={({ index }) => filteredRows[index]}
              sort={handleRequestSort}
              sortBy={orderBy.sortBy}
              sortDirection={orderBy.sortDirection}
              rowRenderer={rowRenderer}
              rowClassName="tableRowHover"
            >
              <Column
                label=""
                dataKey="expand"
                width={50}
                cellRenderer={expandCollapseRenderer}
                disableSort
              />
              <Column
                label="Patient Name"
                dataKey="patientName"
                width={width * 0.3}
                headerRenderer={headerRenderer}
                cellRenderer={({ cellData }) => {
                  return cellData !== ", " ? cellData : "";
                }}
              />
              <Column
                label="Patient ID"
                dataKey="canonicalPatientId"
                width={width * 0.25}
                headerRenderer={headerRenderer}
                cellRenderer={patientIdCellRenderer}
              />
              <Column
                label="Birth Date"
                dataKey="birthDate"
                width={width * 0.25}
                headerRenderer={headerRenderer}
              />
              <Column
                label="Gender"
                dataKey="gender"
                width={width * 0.25}
                headerRenderer={headerRenderer}
              />
              <Column
                label=""
                dataKey="editPatient"
                width={width * 0.1}
                cellRenderer={({ rowIndex }) =>
                  cellRenderer(sortedRows[rowIndex])
                }
                disableSort
              />
            </Table>
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default VirtualizedPatientTable;

import logo from "../images/sidebar/logo.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, analytics, getAuthData } from "../context/AuthContext";
import { logEvent } from "@firebase/analytics";
import logo_small from "../images/sidebar/logo_small.svg";
import storage from "../images/sidebar/storage.svg";
import storageActive from "../images/sidebar/storageActive.svg";
import expand from "../images/sidebar/expand.svg";
import dashboard from "../images/sidebar/session.svg";
import dashboardActive from "../images/sidebar/session_active.svg";
import collapse from "../images/sidebar/collapse.svg";
import exit from "../images/login/exit.svg";
import profile from "../images/login/profile.svg";
import profileActive from "../images/sidebar/profileActive.svg";
import subscription from "../images/sidebar/Star.svg";
import admin from "../images/sidebar/admin.svg";
import patientsActive from "../images/sidebar/patientActive.svg";
import patients from "../images/sidebar/patients.svg";
import usersActive from "../images/sidebar/users_active.svg";
import users from "../images/sidebar/users.svg";
import { UploadContext } from "./App";
import { Link } from "react-router-dom";
import UploadModal from "./modals/uploadFile";
import "../style/feedback_modal.css";
import { VERSION_NUMBER } from "../version_number";
import firestoreRepository from "../repositories/firestoreRepository";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import FreeUser from "./dashboard/free";
import FreeOrg from "./dashboard/free_org";
import cloudFunctionRepository from "../repositories/cloudFunctionRepository";
import { is } from "date-fns/locale";

const iconStyle = {
  height: "30px",
  width: "30px",
  marginLeft: "1.5em",
};

const iconStyleCollapsed = {
  height: "30px",
  width: "30px",
};

const Layout = ({ children }) => {
  // const [authData, setAuthData] = useState({});
  const [adminExpanded, setAdminExpanded] = useState(() => {
    const storedValue = localStorage.getItem("isAdminExpanded");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  const [expanded, setExpanded] = useState(() => {
    const storedValue = localStorage.getItem("isExpanded");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  const { setAuthData } = useContext(UploadContext);
  const { setSideBarWidth } = useContext(UploadContext);
  const { setUploadProgress } = useContext(UploadContext);
  const { orgData, setOrgData } = useContext(UploadContext);
  const { setLastDeviceSync } = useContext(UploadContext);
  const { largestSubscriptionId, setLargestSubscriptionId } =
    useContext(UploadContext);
  const { setOrgDataDetails } = useContext(UploadContext);
  const { setSubscriptionData } = useContext(UploadContext);
  const { isLoading, setIsLoading } = useContext(UploadContext);
  const {
    entityId,
    isOrgUser,
    isAdmin,
    wrtPermissions,
    shrPermission,
    rdPermissions,
    dltPermission,
    setUserClaims,
    setUserData,
    userData,
  } = useContext(UploadContext);
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("authData");
      sessionStorage.removeItem("orgData");
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("orgDataDetails");
      sessionStorage.removeItem("formattedRows");
      sessionStorage.removeItem("subscriptionData");
      sessionStorage.removeItem("largestSubscriptionId");
      sessionStorage.removeItem("formattedData");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const authData = await getAuthData();
        setAuthData(authData);
        sessionStorage.setItem("authData", JSON.stringify(authData));
        // Set entityId and isOrgUser based on the user's claims
        if (!authData.claims.orgId) {
          entityId.current = currentUser.uid;
          isOrgUser.current = false;
        } else {
          entityId.current = authData.claims.orgId;
          isOrgUser.current = true;
        }
        if (authData.claims.isAdmin) {
          isAdmin.current = true;
        } else {
          isAdmin.current = false;
        }

        if (isOrgUser.current) {
          const orgData = await loadOrgData(entityId.current);
          setOrgData(orgData);
          setLastDeviceSync(orgData.lastDeviceSyncRef);
          setLargestSubscriptionId(orgData.stripeSubscriptionId || 0);
          sessionStorage.setItem(
            "largestSubscriptionId",
            JSON.stringify(orgData.stripeSubscriptionId || 0)
          );
          sessionStorage.setItem("orgData", JSON.stringify(orgData));

          const claims = await firestoreRepository.getUserClaims(
            currentUser.uid
          );
          setUserClaims(claims);

          const currentUserData = orgData.users[currentUser.uid];
          if (currentUserData) {
            wrtPermissions.current = currentUserData.pWrt;
            shrPermission.current = currentUserData.pShr;
            rdPermissions.current = currentUserData.pRd;
            dltPermission.current = currentUserData.pDlt;
          }
          // Check if rdPermissions is 0
          if (currentUserData.pRd === 0) {
            console.warn("Read permissions are 0, not fetching data.");
            return;
          }
          const ownersAndTechs = Object.keys(orgData.users).map((userId) => ({
            id: userId,
            name: orgData.users[userId]?.name,
            clrIdx: orgData.users[userId]?.clrIdx,
            roleId: orgData.users[userId]?.roleId,
          }));

          const orgDataToStore = {
            ownerData: ownersAndTechs?.filter((user) => user.roleId === 1),
            techData: ownersAndTechs?.filter((user) => user.roleId === 0),
          };
          setOrgDataDetails(orgDataToStore);

          sessionStorage.setItem(
            "orgDataDetails",
            JSON.stringify(orgDataToStore)
          );
        } else {
          const userData = await loadUserData(currentUser.uid);
          setUserData(userData);
          setLastDeviceSync(userData.lastDeviceSyncRef);
          const largestSubscriptionId = Math.max(
            userData.stripeSubscriptionId || 0,
            userData.iosSubscriptionId || 0,
            userData.androidSubscriptionId || 0,
            userData.premiumAndroid ? 2 : 0,
            userData.premiumIOS ? 2 : 0
          );
          setLargestSubscriptionId(largestSubscriptionId);
          sessionStorage.setItem(
            "largestSubscriptionId",
            JSON.stringify(largestSubscriptionId)
          );
          sessionStorage.setItem("userData", JSON.stringify(userData));
        }
        const subscriptionData =
          await cloudFunctionRepository.getSubscriptionData(authData.token);
        setSubscriptionData(subscriptionData);
        sessionStorage.setItem(
          "subscriptionData",
          JSON.stringify(subscriptionData)
        );
        window.Intercom("update", {
          subscription_end_date: subscriptionData.currentPeriodEnd
            ? subscriptionData.currentPeriodEnd
            : null,
        });
      } catch (error) {
        if (error.code === "permission-denied") {
          console.error(
            "Error getting auth data: Missing or insufficient permissions."
          );
        } else {
          console.error("Error getting auth data: ", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    const loadDataFromLocalStorage = () => {
      const storedAuthData = sessionStorage.getItem("authData");
      if (storedAuthData) {
        setAuthData(JSON.parse(storedAuthData));
      }

      const storedOrgData = sessionStorage.getItem("orgData");
      if (storedOrgData) {
        setOrgData(JSON.parse(storedOrgData));
      }

      const storedUserData = sessionStorage.getItem("userData");
      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }

      const storedOrgDataDetails = sessionStorage.getItem("orgDataDetails");
      if (storedOrgDataDetails) {
        setOrgDataDetails(JSON.parse(storedOrgDataDetails));
      }

      const storedLargeSubscriptionId = sessionStorage.getItem(
        "largestSubscriptionId"
      );
      if (storedLargeSubscriptionId) {
        setLargestSubscriptionId(JSON.parse(storedLargeSubscriptionId));
      }
    };
    loadDataFromLocalStorage();

    fetchData();
  }, [currentUser.uid]);

  useEffect(() => {
    setSideBarWidth(expanded ? "70px" : "200px");
  }, [expanded]);

  useEffect(() => {
    localStorage.setItem("isExpanded", JSON.stringify(expanded));
  }, [expanded]);

  useEffect(() => {
    localStorage.setItem("isAdminExpanded", JSON.stringify(adminExpanded));
  }, [adminExpanded]);

  useEffect(() => {
    loadUserData(currentUser.uid).then((data) => {
      setUserData(data);
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadOrgData = async (orgId) => {
    const data = await firestoreRepository.getOrgData(orgId);
    return data;
  };

  const isActive = (path) => {
    return location.pathname === path ||
      location.pathname.startsWith(`${path}/`)
      ? "active"
      : "";
  };

  const getIconDashboard = (path) => {
    return isActive("/") || isActive("/sessions")
      ? activeIconDashboard
      : defaultIconDashboard;
  };

  const getIconStorage = (path) => {
    return isActive(path) ? activeIconStorage : defaultIconStorage;
  };

  const getIconUsers = (path) => {
    return isActive(path) ? activeIconUsers : defaultIconUsers;
  };

  const getIconPatient = (path) => {
    return isActive(path) ? activeIconPatients : defaultIconPatients;
  };

  const getIconProfile = (path) => {
    return isActive(path) ? activeIconProfile : defaultIconProfile;
  };

  async function handleAccount() {
    try {
      logEvent(analytics, "user_logout", {
        button_name: "user_logout",
      });

      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("startSessionIdx");
      sessionStorage.removeItem("endSessionIdx");
      sessionStorage.removeItem("persistSearchParams");
      sessionStorage.removeItem("persistQueryIdx");
      localStorage.removeItem("isClosed");
      localStorage.removeItem("isCollapsed");
      sessionStorage.removeItem("authData");
      sessionStorage.removeItem("orgData");
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("orgDataDetails");
      sessionStorage.removeItem("formattedRows");
      sessionStorage.removeItem("subscriptionData");
      sessionStorage.removeItem("largestSubscriptionId");
      sessionStorage.removeItem("formattedData");
      sessionStorage.removeItem("checkedOwnerModal");
      sessionStorage.removeItem("checkedTechModal");

      // Reset state variables
      setAuthData({});
      setOrgData({});
      setUserData([]);
      setOrgDataDetails({});
      setSubscriptionData({});
      setLargestSubscriptionId(0);
      setUploadProgress([]);
      setLastDeviceSync(null);
      setUserClaims({});
      wrtPermissions.current = null;
      shrPermission.current = null;
      rdPermissions.current = null;
      dltPermission.current = null;
      isAdmin.current = false;
      isOrgUser.current = false;
      entityId.current = null;

      await logout();
      navigate("/login");
    } catch {}
  }

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const defaultIconDashboard = (
    <img
      src={dashboard}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconDashboard = (
    <img
      src={dashboardActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconPatients = (
    <img
      src={patients}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconPatients = (
    <img
      src={patientsActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconUsers = (
    <img
      src={users}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconUsers = (
    <img
      src={usersActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconStorage = (
    <img
      src={storage}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconStorage = (
    <img
      src={storageActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconProfile = (
    <img
      src={profile}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconProfile = (
    <img
      src={profileActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  return (
    <div className="global-wrapper">
      <header style={!expanded ? { width: "70px" } : {}}>
        {expanded ? (
          <>
            <div className="full_width">
              <div className="logo-container">
                <Link to="/">
                  <img src={logo} className="logo-image" />
                </Link>
                <button
                  variant="link"
                  className="button_no_style"
                  onClick={() => {
                    setTimeout(() => {
                      setExpanded(false);
                    }, 300);
                  }}
                >
                  <img className="fixed-width-img" src={collapse}></img>
                </button>
              </div>
              <div className="overview_text">Overview</div>
              <Link
                to="/"
                className={`sidebar ${
                  isActive("/") || isActive("/sessions")
                } link-margin-top`}
              >
                {getIconDashboard("/")}
                <div className="add_margin_left">Sessions</div>
              </Link>
              <Link
                to="/patients"
                className={`sidebar ${isActive("/patients")} link-margin-top`}
              >
                {getIconPatient("/patients")}
                <div className="add_margin_left">Patients</div>
              </Link>
              {isOrgUser.current === true && isAdmin.current === true ? (
                <>
                  <button
                    onClick={() =>
                      setTimeout(() => {
                        setAdminExpanded(!adminExpanded);
                      }, 300)
                    }
                    className={`button_no_style sidebar link-margin-top  `}
                    style={{ padding: "0" }}
                  >
                    <img src={admin} style={iconStyle} alt="Default Icon" />
                    <div className="add_margin_left">Admin</div>
                    <div className="add_margin_left">
                      {adminExpanded ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </div>{" "}
                  </button>
                  {adminExpanded && (
                    <>
                      <Link
                        to="/users"
                        className={`sidebar ${isActive(
                          "/users"
                        )} link-margin-top`}
                      >
                        {getIconUsers("/users")}
                        <div className="add_margin_left">Users</div>
                      </Link>
                      <Link
                        to="/storage"
                        className={`sidebar ${isActive("/storage")}`}
                      >
                        {getIconStorage("/storage")}
                        <div className="add_margin_left">Cloud Storage</div>
                      </Link>
                      <Link
                        to="/manage"
                        className={`sidebar ${isActive("/manage")}`}
                      >
                        <img
                          src={subscription}
                          style={iconStyle}
                          alt="Default Icon"
                        />
                        <div className="add_margin_left">Subscription</div>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {isOrgUser.current === false ? (
                <Link
                  to="/storage"
                  className={`sidebar ${isActive("/storage")} link-margin-top`}
                >
                  {getIconStorage("/storage")}
                  <div className="add_margin_left">Cloud Storage</div>
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="full_width ">
              <hr className="dark-hr"></hr>
              <Link to="/account" className={`sidebar ${isActive("/account")}`}>
                {getIconProfile("/account")}
                <div className="add_margin_left">My Profile</div>
              </Link>
              {isOrgUser.current === false ? (
                <Link to="/manage" className={`sidebar ${isActive("/manage")}`}>
                  <img
                    src={subscription}
                    style={iconStyle}
                    alt="Default Icon"
                  />
                  <div className="add_margin_left">Subscription</div>
                </Link>
              ) : (
                ""
              )}
              <button
                variant="link"
                className="button_no_style sidebar button-margin-padding"
                onClick={() => {
                  handleAccount();
                }}
              >
                <img style={iconStyle} src={exit}></img>
                <div className="add_margin_left">Log out</div>
              </button>
              {isOrgUser.current === true ? (
                <div className="version_text">
                  You are in a business tier account
                </div>
              ) : (
                ""
              )}
              <div className="version_text">version {VERSION_NUMBER}</div>{" "}
            </div>
          </>
        ) : (
          <>
            <div className="full_width">
              <div className="sidebar sidebar-centered">
                <Link to="/">
                  <img src={logo_small} className="fixed-dimension-img"></img>
                </Link>
              </div>{" "}
              <button
                variant="link"
                className="button_no_style sidebar sidebar-centered"
                onClick={() => {
                  setTimeout(() => {
                    setExpanded(true);
                  }, 300);
                }}
              >
                <img className="fixed-dimension-img-40" src={expand}></img>
              </button>
              <Link
                to="/"
                className={`sidebar sidebar-centered ${
                  isActive("/") || isActive("/sessions")
                }`}
              >
                {getIconDashboard("/")}
              </Link>
              <Link
                to="/patients"
                className={`sidebar ${isActive("/patients")} sidebar-centered`}
              >
                {getIconPatient("/patients")}
              </Link>
              {isOrgUser.current === true && isAdmin.current === true ? (
                <>
                  <button
                    onClick={() =>
                      setTimeout(() => {
                        setAdminExpanded(!adminExpanded);
                      }, 300)
                    }
                    className={`button_no_style sidebar link-margin-top `}
                  >
                    <img
                      src={admin}
                      style={iconStyleCollapsed}
                      alt="Default Icon"
                    />
                    <div>
                      {adminExpanded ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </div>{" "}
                  </button>
                  {adminExpanded && (
                    <>
                      <Link
                        to="/users"
                        className={`sidebar ${isActive(
                          "/users"
                        )} link-margin-top`}
                        style={{ justifyContent: "center" }}
                      >
                        {getIconUsers("/users")}
                      </Link>
                      <Link
                        to="/storage"
                        className={`sidebar ${isActive("/storage")}`}
                        style={{ justifyContent: "center" }}
                      >
                        {getIconStorage("/storage")}
                      </Link>
                      <Link
                        to="/manage"
                        className={`sidebar ${isActive("/manage")}`}
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          src={subscription}
                          style={iconStyleCollapsed}
                          alt="Default Icon"
                        />
                      </Link>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {isOrgUser.current === false ? (
                <Link
                  to="/storage"
                  className={`sidebar ${isActive("/storage")}`}
                  style={{ justifyContent: "center" }}
                >
                  {getIconStorage("/storage")}
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="full_width">
              <hr className="dark-hr"></hr>
              <Link
                to="/account"
                className={`sidebar ${isActive("/account")}`}
                style={{ justifyContent: "center" }}
              >
                {getIconProfile("/account")}
              </Link>
              {isOrgUser.current === false ? (
                <Link
                  to="/manage"
                  className={`sidebar ${isActive("/manage")}`}
                  style={{ justifyContent: "center" }}
                >
                  <img
                    src={subscription}
                    style={iconStyleCollapsed}
                    alt="Default Icon"
                  />
                </Link>
              ) : (
                ""
              )}
              <button
                variant="link"
                className="button_no_style sidebar  button-margin-center"
                onClick={() => {
                  handleAccount();
                }}
              >
                <img style={iconStyleCollapsed} src={exit}></img>
              </button>
            </div>
          </>
        )}
      </header>
      <main
        style={{ marginLeft: isMobile ? "70px" : expanded ? "200px" : "70px" }}
      >
        {/* {largestSubscriptionId !== null && largestSubscriptionId > 0 ? ( */}
        <div>{children}</div>
        {/* ) : ( */}
        {/* <> */}
        {/* {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
              </div>
            ) : (
              <div>{isOrgUser.current ? <FreeOrg /> : <FreeUser />}</div>
            )} */}
        {/* </>
        )} */}

        <div className="fixed-div">
          <UploadModal />
        </div>
      </main>
    </div>
  );
};

export default Layout;

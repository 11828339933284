import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../Layout";
import { Box, CircularProgress, Grid, Modal, Tooltip } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import profile from "../../images/login/profile_black.svg";
import country from "../../images/login/flag.svg";
import profession from "../../images/login/profession.svg";
import email from "../../images/login/email.svg";
import "../../style/login.css";
import "../../style/account.css";
import google from "./google.svg";
import apple from "./apple.svg";
import password from "./password.svg";
import axios from "axios";
import ChangePassword from "./change_password";
import lock from "./Lock.svg";
import firestoreRepository from "../../repositories/firestoreRepository";
import user from "./user.svg";
import company from "./company.svg";
import { UploadContext } from "../App";

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflow: "auto",
  p: 4,
};

const Account = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userSpeciality, setUserSpeciality] = useState("");
  const [userDataCountry, setUserDataCountry] = useState();
  const [userDataEmail, setUserDataEmail] = useState();
  const [userDataSpeciality, setUserDataSpeciality] = useState();
  const [fullName, setFullName] = useState("");

  const [countries, setCountries] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [openNameModal, setOpenNameModal] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [openSpecialtyModal, setOpenSpecialtyModal] = useState(false);

  const [pDltText, setPDltText] = useState("");
  const [pShrText, setPShrText] = useState("");
  const [pRdText, setPRdText] = useState("");
  const [pWrtText, setPWrtText] = useState("");

  const prevCountry = useRef(null);
  const prevSpeciality = useRef(null);
  const prevName = useRef(null);

  const {
    wrtPermissions,
    shrPermission,
    rdPermissions,
    dltPermission,
    userData,
    orgData,
    isOrgUser,
  } = useContext(UploadContext);

  const specialities = [
    "Ophthalmology",
    "Dentistry",
    "Orthopedics",
    "ENT",
    "Neurology",
    "Plastic Surgery",
    "Histopathology",
    "Cardiovascular",
    "Optometrist",
    "Sales Representative",
    "Clinical Specialist",
    "Other",
  ];

  const getPermissionString = (permission) => {
    switch (permission) {
      case 0:
        return "NONE";
      case 1:
        return "SELF";
      case 2:
        return "ALL";
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!userData) {
        setIsLoading(true);
        return;
      }

      setPDltText(getPermissionString(dltPermission.current));
      setPShrText(getPermissionString(shrPermission.current));
      setPRdText(getPermissionString(rdPermissions.current));
      setPWrtText(getPermissionString(wrtPermissions.current));

      if (userData.firstName && userData.lastName) {
        setFullName(`${userData.firstName} ${userData.lastName}`);
      } else if (userData.firstName) {
        setFullName(userData.firstName);
      } else if (userData.lastName) {
        setFullName(userData.lastName);
      } else {
        setFullName("");
      }
      setUserDataCountry(userData.country);
      setUserDataEmail(userData.email);
      setUserDataSpeciality(userData.speciality);
      prevName.current = fullName;
      prevCountry.current = userData.country;
      prevSpeciality.current = userData.speciality;

      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");

        const sortedCountries = response.data.sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });
        setCountries(sortedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }

      setIsLoading(false); // Set loading to false after all data is loaded
    };

    fetchData();
  }, [
    userData,
    dltPermission.current,
    shrPermission.current,
    rdPermissions.current,
    wrtPermissions.current,
  ]);

  let deleteTooltipText;
  switch (pDltText) {
    case "NONE":
      deleteTooltipText = "You cannot delete anyone's data.";
      break;
    case "SELF":
      deleteTooltipText = "You can only delete your data.";
      break;
    case "ALL":
      deleteTooltipText =
        "You can delete data from anyone in the organization.";
      break;
    default:
      deleteTooltipText = "Delete permission level is undefined";
  }

  let shareTooltipText;
  switch (pShrText) {
    case "NONE":
      shareTooltipText = "You cannot share anyone's data.";
      break;
    case "SELF":
      shareTooltipText = "You can only share your data.";
      break;
    case "ALL":
      shareTooltipText = "You can share data from anyone in the organization.";
      break;
    default:
      shareTooltipText = "Share permission level is undefined";
  }

  let readTooltipText;
  switch (pRdText) {
    case "SELF":
      readTooltipText = "You can only see your data";
      break;
    case "ALL":
      readTooltipText = "You can see the data for the whole organization.";
      break;
    default:
      readTooltipText = "Read permission level is undefined";
  }

  let writeTooltipText;
  switch (pWrtText) {
    case "SELF":
      writeTooltipText = "You can only edit your data";
      break;
    case "ALL":
      writeTooltipText = "You can edit the data for the whole organization.";
      break;
    default:
      writeTooltipText = "Edit permission level is undefined";
  }

  const updateName = (uid, fullName) => {
    const nameArray = fullName.split(" ");
    const data = {
      firstName: nameArray[0],
      lastName: fullName.includes(" ") ? nameArray.slice(1).join(" ") : "",
    };
    setFullName(fullName);
    prevName.current = fullName;
    firestoreRepository.updateUser(uid, data);
  };

  const updateCountry = (uid, userCountry) => {
    setUserDataCountry(userCountry);
    prevCountry.current = userCountry;
    firestoreRepository.updateUser(uid, { country: userCountry });
  };

  const updateSpeciality = (uid, userSpeciality) => {
    setUserDataSpeciality(userSpeciality);
    prevSpeciality.current = userSpeciality;
    firestoreRepository.updateUser(uid, { speciality: userSpeciality });
  };

  const handleCountryChange = (event) => {
    setUserCountry(event.target.value);
  };

  const handleSpecialityChange = (event) => {
    setUserSpeciality(event.target.value);
  };

  return (
    <>
      <Layout>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <div className="my_profile_text">My Profile</div>{" "}
            {isOrgUser.current && (
              <div className="company_info_div">
                <div className="company_info_text">COMPANY INFORMATION</div>
                <hr className="hr_style"></hr>
                <Grid
                  container
                  padding={"0px 20px 20px 20px"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={orgData.name?.length > 25 ? 5.8 : 2.9}
                    marginTop={"0.5em"}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={1.5}>
                          <img src={company}></img>
                        </Grid>
                        <Grid item xs={10} fontWeight={"700"}>
                          Company Name
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="input_style"
                        style={{
                          paddingTop: "5px",
                          marginTop: "0.5em",
                          paddingLeft: "20px",
                          borderRadius: "20px",
                        }}
                      >
                        {orgData.name}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={orgData.name?.length > 25 ? 5.8 : 2.9}
                    marginTop={"0.5em"}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={1.5}>
                          <img src={user}></img>
                        </Grid>
                        <Grid item xs={10} fontWeight={"700"}>
                          Role
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="input_style"
                        style={{
                          paddingTop: "5px",
                          marginTop: "0.5em",
                          paddingLeft: "20px",
                          borderRadius: "20px",
                        }}
                      >
                        {userData.roleId === 0 ? "Technician" : "Clinician"}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} marginTop={"0.5em"}>
                    <Grid container alignItems={"center"}>
                      <Grid container xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          fontWeight={"700"}
                          sx={{ marginBottom: { xs: "0.5em", sm: "0em" } }}
                        >
                          Permissions
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={9}
                          style={{
                            backgroundColor: "#323232",
                            padding: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={3}
                              style={{ color: "white", textAlign: "center" }}
                            >
                              VIEW
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ color: "white", textAlign: "center" }}
                            >
                              EDIT
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ color: "white", textAlign: "center" }}
                            >
                              DELETE
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ color: "white", textAlign: "center" }}
                            >
                              SHARE
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} marginTop={"0.5em"}>
                        <Grid item xs={3}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={9}
                          style={{
                            backgroundColor: "white",
                            padding: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid container justifyContent={"space-between"}>
                            <Tooltip title={readTooltipText}>
                              <Grid
                                item
                                xs={2.5}
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                }}
                              >
                                {pRdText}
                              </Grid>
                            </Tooltip>
                            <Tooltip title={writeTooltipText}>
                              <Grid
                                item
                                xs={2.5}
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                }}
                              >
                                {pWrtText}
                              </Grid>
                            </Tooltip>
                            <Tooltip title={deleteTooltipText}>
                              <Grid
                                item
                                xs={2.5}
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                }}
                              >
                                {pDltText}
                              </Grid>
                            </Tooltip>
                            <Tooltip title={shareTooltipText}>
                              <Grid
                                item
                                xs={2.5}
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                }}
                              >
                                {pShrText}
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <div style={{ marginTop: "2em", fontWeight: "600" }}>
              ACCOUNT INFORMATION
            </div>
            <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
            <div
              style={{
                width: "100%",
              }}
            >
              <div className="profile_flex">
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={profile}
                      style={{ width: "24px", marginRight: "1em" }}
                    ></img>
                    <div style={{ fontWeight: "700" }}>Name</div>
                  </div>
                  <div
                    key={"name"}
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                      marginTop: "1em",
                    }}
                  >
                    <button
                      onClick={() => {
                        setOpenNameModal(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {fullName}
                    </button>
                    <Modal
                      open={openNameModal}
                      onClose={() => setOpenNameModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleee}>
                        <div
                          style={{
                            backgroundColor: "#FDFDFD",
                            padding: "30px 0px",
                          }}
                        >
                          <label className="label">Your Full Name</label>
                          <textarea
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          ></textarea>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1em",
                            }}
                          >
                            <button
                              className="button_no_style"
                              onClick={() => {
                                setFullName(prevName.current);
                                setOpenNameModal(false);
                              }}
                            >
                              Cancel
                            </button>
                            {fullName ? (
                              <button
                                className="button_no_style"
                                style={{
                                  color: "#8F4FFF",
                                  fontWeight: "600",
                                }}
                                onClick={() => {
                                  updateName(currentUser.uid, fullName);
                                  setOpenNameModal(false);
                                }}
                              >
                                Update
                              </button>
                            ) : (
                              <div>
                                <button
                                  className="button_no_style"
                                  style={{ fontWeight: "600" }}
                                  onClick={() => {
                                    setError("Invalid full name!");
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            )}
                          </div>
                        </div>{" "}
                        {!fullName && (
                          <div>
                            {error && (
                              <div
                                style={{
                                  marginTop: "0.5em",
                                  textAlign: "center",
                                  color: "red",
                                }}
                              >
                                {error}
                              </div>
                            )}
                          </div>
                        )}
                      </Box>
                    </Modal>
                  </div>
                </div>
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={country}
                      style={{ width: "24px", marginRight: "0.5em" }}
                    ></img>
                    <div style={{ fontWeight: "700" }}>Country</div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                      marginTop: "1em",
                    }}
                  >
                    <button
                      onClick={() => {
                        setOpenCountryModal(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {userDataCountry}
                    </button>
                    <Modal
                      open={openCountryModal}
                      onClose={() => setOpenCountryModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      key={userDataCountry}
                    >
                      <Box sx={styleee}>
                        <div
                          style={{
                            backgroundColor: "#FDFDFD",
                            padding: "30px 0px",
                          }}
                        >
                          <label className="label">Your Country</label>
                          <div>
                            <select
                              style={{
                                padding: "8px",
                                fontSize: "16px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                outline: "none",
                                width: "100%",
                                backgroundColor: "#FDFDFD",
                                color: "#333",
                                marginTop: "1em",
                              }}
                              onChange={handleCountryChange}
                              value={userCountry}
                            >
                              <option value={userDataCountry}>
                                {userDataCountry}
                              </option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.name.common}>
                                  {country.name.common}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1em",
                            }}
                          >
                            <button
                              className="button_no_style"
                              onClick={() => {
                                setUserCountry(prevCountry.current);
                                setOpenCountryModal(false);
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              className="button_no_style"
                              style={{
                                color: "#8F4FFF",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                updateCountry(currentUser.uid, userCountry);
                                setOpenCountryModal(false);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Box>
                    </Modal>{" "}
                  </div>
                </div>
              </div>
              <div className="profile_flex">
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={email}
                      style={{ width: "24px", marginRight: "0.5em" }}
                    ></img>
                    <div style={{ fontWeight: "700" }}>Email</div>{" "}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                      marginTop: "1em",
                    }}
                  >
                    <Tooltip
                      title="You can not change your email address"
                      arrow
                      placement="top"
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "10px 20px",
                          overflowWrap: "break-word",
                          fontSize: "16px",
                        }}
                      >
                        {userDataEmail}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={profession}
                      style={{ width: "24px", marginRight: "0.5em" }}
                    ></img>
                    <div style={{ fontWeight: "700" }}>Occupation</div>
                  </div>
                  <div
                    key={"speciality"}
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                      marginTop: "1em",
                    }}
                  >
                    <button
                      onClick={() => {
                        setOpenSpecialtyModal(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {userDataSpeciality}
                    </button>

                    <Modal
                      open={openSpecialtyModal}
                      onClose={() => setOpenSpecialtyModal(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleee}>
                        <div
                          style={{
                            backgroundColor: "#FDFDFD",
                            padding: "30px 0px",
                          }}
                        >
                          <label className="label">Your speciality</label>
                          <div>
                            <select
                              style={{
                                padding: "8px",
                                fontSize: "16px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                outline: "none",
                                width: "100%",
                                backgroundColor: "#FDFDFD",
                                color: "#333",
                                marginTop: "1em",
                              }}
                              onChange={handleSpecialityChange}
                              value={userSpeciality}
                            >
                              <option value={userDataSpeciality}>
                                {userDataSpeciality}
                              </option>
                              {specialities.map((speciality, index) => (
                                <option key={index} value={speciality}>
                                  {speciality}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1em",
                            }}
                          >
                            <button
                              className="button_no_style"
                              onClick={() => {
                                setUserSpeciality(prevSpeciality.current);
                                setOpenSpecialtyModal(false);
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              className="button_no_style"
                              style={{
                                color: "#8F4FFF",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                updateSpeciality(
                                  currentUser.uid,
                                  userSpeciality
                                );
                                setOpenSpecialtyModal(false);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>{" "}
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  You login using{" "}
                  {userData.signinMethod === "google" ? (
                    <>
                      Google <img src={google}></img>
                    </>
                  ) : userData.signinMethod === "apple" ? (
                    <>
                      Apple ID{" "}
                      <img src={apple} style={{ marginLeft: "0.5em" }}></img>
                    </>
                  ) : (
                    <>
                      email & password
                      <img src={password} style={{ marginLeft: "0.5em" }}></img>
                    </>
                  )}
                </div>
              </div>
            </div>
            {userData.signinMethod === "apple" ||
            userData.signinMethod === "google" ? (
              ""
            ) : (
              <div style={{ marginBottom: "3em" }}>
                <div
                  style={{
                    marginTop: "2.5em",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={lock} style={{ marginRight: "0.5em" }}></img>
                  <div>CHANGE PASSWORD</div>
                </div>
                <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                <Grid
                  container
                  justifyContent={"space-between"}
                  style={{ marginTop: "1em" }}
                >
                  <Grid item xs={12} md={5}>
                    <div>Do you need to update your password?</div>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ChangePassword />
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};
export default Account;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useAuth, getAuthData } from "../../context/AuthContext";
import { analytics } from "../../firebase";
import "../../style/dashboard.css";
import DownloadIconBlack from "../../images/dashboard/download.svg";
import DownloadDisabled from "../../images/dashboard/download_disabled.svg";
import { Container } from "@mui/system";
import Layout from "../Layout";
import Box from "@mui/material/Box";
import "../../style/gallery.css";
import FreeUser from "./free";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logEvent } from "@firebase/analytics";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "../dashboard/Dashboard.css";
import Upload from "../gallery/upload";
import pause from "../../images/gallery/Pause.svg";
import { UploadContext } from "../App";
import { ArrowBack } from "@mui/icons-material";
import ModalEditSession from "../common/ModalEditSession";
import ModalDeleteSession from "../common/ModalDeleteSession";
import Multiselect from "../gallery/multiselect";
import firestoreRepository, {
  listenDeviceSyncChanges,
} from "../../repositories/firestoreRepository";
import Gallery from "../gallery/fileDetailedView";
import { downloadFiles } from "../gallery/downloadFile";
import info from "../subscription/info.svg";
import { SessionOwner } from "../../utils/ownerUtils";
import { SessionTechnician } from "../../utils/technicianUtils";
import PatientModal from "../common/ModalPatientInfo";
import FreeOrg from "./free_org";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  let { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    setFiles,
    setUploadProgress,
    setUploadThumbnails,
    setRemainingTimes,
    renderVideo,
    setRenderVideo,
    entityId,
    isOrgUser,
    largestSubscriptionId,
    lastDeviceSyncRef,
    setLastDeviceSync,
    dltPermission,
    shrPermission,
    wrtPermissions,
    rdPermissions,
    userData,
    isLoading,
    orgData,
  } = useContext(UploadContext);
  const [downloadingFiles, setDownloadingFiles] = useState(new Set());
  const [multiselectedFiles, setMultiselectedFiles] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [multiselectedUrls, setMultiselectedUrls] = useState([]);
  const [multiSelectedPaths, setMultiSelectedPaths] = useState([]);
  const [openedFile, setOpenedFile] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [session, setSession] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [patient, setPatient] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [value, setValue] = useState("All");

  const debounceTimer = useRef(null);

  useEffect(() => {
    if (entityId.current === null || isOrgUser.current === null) {
      return;
    } else if (entityId.current !== null && isOrgUser.current !== null) {
      loadData(entityId.current, isOrgUser.current, id);
    }
  }, [needReload, entityId.current, isOrgUser.current]);

  useEffect(() => {
    getAuthData()
      .then((authData) => {
        // Set up listener for device sync changes

        const fetchData = async () => {
          if (entityId.current && isOrgUser.current) {
            const loadDataCallback = () => {
              loadData(entityId.current, isOrgUser.current, id);
            };
            const unsubscribe = await listenDeviceSyncChanges(
              entityId.current,
              isOrgUser.current,
              lastDeviceSyncRef,
              setLastDeviceSync,
              loadDataCallback,
              debounceTimer
            );

            return () => unsubscribe();
          }
        };

        fetchData();
        setNeedReload(!needReload);
      })
      .catch((error) => {
        console.error("Error getting auth data: ", error);
      });
  }, [currentUser.uid]);

  const handleSelectImage = (media) => {
    if (multiselectedFiles.find((item) => item.globalId === media.globalId)) {
      setMultiselectedFiles(
        multiselectedFiles.filter((item) => item.globalId !== media.globalId)
      );
    } else {
      setMultiselectedFiles([...multiselectedFiles, media]);
    }
  };

  function handleImageClick(url, downloadURL) {
    if (multiselectedUrls.includes(downloadURL)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== downloadURL)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, downloadURL]);
    }
    if (multiSelectedPaths.includes(url)) {
      setMultiSelectedPaths((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== url)
      );
    } else {
      setMultiSelectedPaths((prevUrls) => [...prevUrls, url]);
    }
  }
  const handleDownload = (file) => {
    downloadFiles(file);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setMultiselectedFiles([]);
    setMultiselectedUrls([]);
    setMultiSelectedPaths([]);
  };

  const loadData = async (entityId, isOrgUser, globalId) => {
    loadSessions(entityId, isOrgUser, globalId).then((session) => {
      setSession(session);

      loadImages(entityId, isOrgUser, session).then((images) => {
        setImages(images);
      });
      loadVideos(entityId, isOrgUser, session).then((videos) => {
        setVideos(videos);
      });
      loadPatients(entityId, isOrgUser, session).then((patient) => {
        setPatient(patient);
      });
    });
    // Since we need all tags, they are independent from the session query
    loadAllTags(entityId, isOrgUser).then((tags) => {
      setAllTags(tags);
    });
    loadAllPatients(entityId, isOrgUser).then((patients) => {
      setAllPatients(patients);
    });
  };

  /**
   * Loads session from the Firestore database.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Array} - An array of session data.
   */
  const loadSessions = async (entityId, isOrgUser, globalId) => {
    const sessionData = await firestoreRepository.getSessionById(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionData;
  };

  /**
   * Loads images for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session images.
   */
  const loadImages = async (entityId, isOrgUser, globalId) => {
    const sessionImages = await firestoreRepository.getImagesBySession(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionImages;
  };

  /**
   * Loads videos for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session videos.
   */
  const loadVideos = async (entityId, isOrgUser, globalId) => {
    const sessionVideos = await firestoreRepository.getVideosBySession(
      entityId,
      isOrgUser,
      globalId
    );

    return sessionVideos;
  };

  const loadPatients = async (entityId, isOrgUser, globalId) => {
    const sessionPatient = await firestoreRepository.getPatientBySession(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionPatient;
  };

  const loadAllPatients = async (entityId, isOrgUser) => {
    const patientsData = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    return patientsData;
  };

  const loadAllTags = async (entityId, isOrgUser) => {
    const tags = await firestoreRepository.getAllTags(entityId, isOrgUser);
    return tags;
  };

  const openGallery = (media, mediaArray) => {
    logEvent(analytics, "file_open", {
      button_name: "file_open",
    });
    if (multiselectedUrls.includes(media.downloadURL)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== media.downloadURL)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, media.downloadURL]);
    }
    setAllFiles(mediaArray);
    setOpenedFile([media]);
    setMultiselectedFiles([]);
    setMultiSelectedPaths([]);
    setMultiselectedUrls([]);

    if (media.globalId.includes("VIDEO")) {
      setGalleryVisible(true);
      navigate("?video");
    } else if (media.globalId.includes("IMAGE")) {
      setGalleryVisible(true);
      navigate("?image");
    } else {
      setGalleryVisible(false);
    }
  };

  const allowedToWrite =
    (isOrgUser.current &&
      currentUser.uid === session.owner &&
      wrtPermissions.current === 1) ||
    (isOrgUser.current && wrtPermissions.current === 2) ||
    !isOrgUser.current;

  const noShrPermission =
    shrPermission.current === 0 ||
    (currentUser.uid !== session.owner && shrPermission.current === 1);

  if (
    userData &&
    Object.keys(userData).length > 0 &&
    largestSubscriptionId === 0 &&
    isOrgUser.current === false
  ) {
    return (
      <Layout>
        <FreeUser></FreeUser>
      </Layout>
    );
  } else if (
    orgData &&
    Object.keys(orgData).length > 0 &&
    largestSubscriptionId === 0 &&
    isOrgUser.current
  ) {
    return (
      <Layout>
        <FreeOrg></FreeOrg>
      </Layout>
    );
  }

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <>
          <>
            {session.deleted === true ? (
              <Container maxWidth="lg" className="container_top">
                <div>
                  <a href="/" className="back_link">
                    <IconButton size="small">
                      <ArrowBack />
                    </IconButton>
                  </a>
                  <div className="deleted_session">
                    This session was deleted
                  </div>
                </div>
              </Container>
            ) : (
              <Container maxWidth="lg" className="container_bottom">
                {multiselectedFiles.length != 0 && (
                  <Multiselect
                    entityId={entityId.current}
                    isOrgUser={isOrgUser.current}
                    multiselectedUrls={multiselectedUrls}
                    multiSelectedPaths={multiSelectedPaths}
                    setMultiSelectedPaths={setMultiSelectedPaths}
                    currentUser={currentUser.uid}
                    reloadCallback={() => setNeedReload(!needReload)}
                    multiselectedFiles={multiselectedFiles}
                    setMultiselectedFiles={setMultiselectedFiles}
                    setMultiselectedUrls={setMultiselectedUrls}
                    sessionId={id}
                    downloadingFiles={downloadingFiles}
                    setDownloadingFiles={setDownloadingFiles}
                    dltPermission={dltPermission.current}
                    sessionOwner={session.owner}
                    shrPermission={shrPermission.current}
                    sessionData={session}
                    patientId={patient.patientId}
                  />
                )}
                <div className="flex_container">
                  <Link to="/" className="back_link">
                    <IconButton size="small">
                      <ArrowBack />
                    </IconButton>
                  </Link>

                  <div>
                    <div className="flex_div_center">
                      {(() => {
                        const mediaArray = [
                          ...(Array.isArray(videos) ? videos : []),
                          ...(Array.isArray(images) ? images : []),
                        ].sort(
                          (a, b) =>
                            new Date(b.createdAt.toDate()) -
                            new Date(a.createdAt.toDate())
                        );

                        return (
                          <Tooltip
                            title={
                              noShrPermission
                                ? "You don't have the required permissions to download files"
                                : ""
                            }
                          >
                            <button
                              className="button_no_style edit_session_button"
                              value={session}
                              disabled={noShrPermission}
                              onClick={() => {
                                const filesToDownload = [];
                                mediaArray.forEach((index) => {
                                  if (!downloadingFiles.has(index.globalId)) {
                                    const url = index.url;
                                    const name = index.globalId;
                                    const thumbnail = index.thumbnailURL;
                                    const size = index.size;
                                    filesToDownload.push({
                                      name: name,
                                      url: url,
                                      size: size,
                                      thumbnail: thumbnail,
                                    });
                                  }
                                });
                                if (filesToDownload.length > 0) {
                                  handleDownload(filesToDownload);
                                }
                              }}
                            >
                              <img
                                src={
                                  noShrPermission
                                    ? DownloadDisabled
                                    : DownloadIconBlack
                                }
                                className="edit_session_icon"
                              />
                            </button>
                          </Tooltip>
                        );
                      })()}

                      {session && entityId.current && (
                        <>
                          <ModalEditSession
                            key={`mes-${session.globalId}-${patient?.patientId?.length}-${allTags?.length}`}
                            sessionObj={session}
                            allPatients={allPatients}
                            patientName={
                              patient ? patient.patientId : undefined
                            }
                            allTagsArray={allTags}
                            entityId={entityId.current}
                            isOrgUser={isOrgUser.current}
                            reloadCallback={() => setNeedReload(!needReload)}
                            owner={session.owner}
                            tech={session.technician}
                            currentUser={currentUser.uid}
                            wrtPermissions={wrtPermissions.current}
                            rdPermissions={rdPermissions.current}
                          />
                          <ModalDeleteSession
                            key={`mds-${session.globalId}`}
                            sessionId={session.globalId}
                            entityId={entityId.current}
                            isOrgUser={isOrgUser.current}
                            reloadCallback={() => setNeedReload(!needReload)}
                            dltPermission={dltPermission.current}
                            sessionOwner={session.owner}
                            currentUser={currentUser.uid}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <>
                  <div className="data_flex_session data_flex_session_inside">
                    <Grid
                      item
                      xs={5.8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label className="label">PATIENT ID:</label>
                      <p className="input_style edit_modal_input">
                        {patient.patientId && patient.patientId.length > 0 ? (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "0px",
                            }}
                          >
                            {patient.patientId}
                            <div></div>
                            <PatientModal
                              entityId={entityId.current}
                              isOrgUser={isOrgUser.current}
                              wrtPermissions={wrtPermissions.current}
                              rdPermissions={rdPermissions.current}
                              firstName={patient.firstName}
                              lastName={patient.lastName}
                              patientId={patient.patientId}
                              birthDate={patient.dateOfBirth}
                              gender={patient.genderIdx}
                              allPatients={allPatients}
                              patientGlobalId={patient.globalId}
                              reloadCallback={() => setNeedReload(!needReload)}
                            />
                          </p>
                        ) : (
                          <span className="no_patient_id">
                            No patient ID assigned
                          </span>
                        )}
                      </p>
                      <label className="label margin_top_1">DATE:</label>
                      <p className="input_style edit_modal_input">
                        {session.createdAt?.toDate().toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </p>
                      <label className="label margin_top_1">TAGS:</label>
                      <div className="width_100" style={{ width: "100%" }}>
                        {session.tags && session.tags.length > 0 ? (
                          <div
                            className="tags_block width_100"
                            style={{ width: "100%" }}
                          >
                            {session.tags?.map((tag) => (
                              <div
                                key={`tagsBlock-${tag}`}
                                className=" margin_top_wrap tags_div"
                                style={{ width: "auto" }}
                              >
                                {`${tag}`
                                  ? `${tag}`.length > 25
                                    ? `${tag}`.slice(0, 25) + "..."
                                    : `${tag}`
                                  : ""}
                              </div>
                            ))}{" "}
                          </div>
                        ) : (
                          <span className="no_tags_added_div">
                            No tags added
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={5.8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {session && isOrgUser.current && (
                        <>
                          <label className="label margin_bottom_05">
                            CLINICIAN:
                          </label>
                          <SessionOwner uid={session.owner} />
                          <label className="label margin_bottom_05 margin_top_1">
                            TECHNICIAN:
                          </label>
                          <SessionTechnician uid={session.technician} />
                        </>
                      )}

                      <label className="label margin_bottom_05 margin_top_1">
                        Comment:
                      </label>
                      <Grid
                        style={{
                          width: "100%",
                          padding: "15px",
                          height: "100%",
                        }}
                        item
                        className="description-wrap"
                      >
                        <Typography>
                          {session.description?.length > 0
                            ? session.description
                            : "A comment or note about a patient, photo or video description"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </>

                {session && entityId.current && (
                  <Upload
                    setUploadProgress={setUploadProgress}
                    setUploadThumbnails={setUploadThumbnails}
                    setFiles={setFiles}
                    setRemainingTimes={setRemainingTimes}
                    entityId={entityId.current}
                    isOrgUser={isOrgUser.current}
                    reloadCallback={() => setNeedReload(!needReload)}
                    renderVideo={renderVideo}
                    setRenderVideo={setRenderVideo}
                    sessionId={id}
                    largestSubscriptionId={largestSubscriptionId}
                    userData={userData}
                    isAllowedToWrite={allowedToWrite}
                  />
                )}
                <div className="margin_left_1">
                  <FormControl>
                    <RadioGroup
                      onChange={handleChange}
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="All"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="All"
                        control={
                          <Radio
                            {...a11yProps(0)}
                            sx={{
                              color: "#8F4FFF",
                              "&.Mui-checked": {
                                color: "#8F4FFF",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                      <FormControlLabel
                        value="Videos"
                        control={
                          <Radio
                            {...a11yProps(1)}
                            sx={{
                              color: "#8F4FFF",
                              "&.Mui-checked": {
                                color: "#8F4FFF",
                              },
                            }}
                          />
                        }
                        label="Videos"
                      />
                      <FormControlLabel
                        value="Images"
                        control={
                          <Radio
                            {...a11yProps(2)}
                            sx={{
                              color: "#8F4FFF",
                              "&.Mui-checked": {
                                color: "#8F4FFF",
                              },
                            }}
                          />
                        }
                        label="Images"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {images && videos ? (
                  <TabPanel
                    value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                    index={0}
                  >
                    <div className="images_page">
                      {(() => {
                        const mediaArray = [
                          ...(largestSubscriptionId === 1
                            ? []
                            : Array.isArray(videos)
                            ? videos
                            : []),
                          ...(Array.isArray(images) ? images : []),
                        ].sort(
                          (a, b) =>
                            new Date(b.createdAt.toDate()) -
                            new Date(a.createdAt.toDate())
                        );
                        return [...Array(mediaArray.length)].map((_, index) => {
                          const media = mediaArray[index];
                          return (
                            <React.Fragment key={`${id}_${index}`}>
                              <div>
                                <div className="hover_over">
                                  <link
                                    rel="preload"
                                    as="image"
                                    href={media.thumbnailSessionURL}
                                  ></link>
                                  <div className="image-container">
                                    {media.globalId.includes("VIDEO") && (
                                      <div className="pause_button">
                                        <img src={pause}></img>
                                      </div>
                                    )}
                                    <img
                                      src={media.thumbnailSessionURL}
                                      alt=""
                                      className="myimg"
                                      onError={async (e) => {
                                        try {
                                          const refetchedMedia =
                                            await firestoreRepository.refetchDownloadUrls(
                                              entityId.current,
                                              isOrgUser.current,
                                              media
                                            );
                                          if (refetchedMedia) {
                                            const isImage =
                                              refetchedMedia.globalId.includes(
                                                "IMAGE"
                                              );
                                            e.target.src =
                                              refetchedMedia.thumbnailSessionURL;
                                            if (isImage) {
                                              setImages((prevImages) => {
                                                const currentImages =
                                                  prevImages[
                                                    session.globalId
                                                  ] || [];
                                                return {
                                                  ...prevImages,
                                                  [session.globalId]: [
                                                    ...currentImages.filter(
                                                      (image) =>
                                                        image.globalId !==
                                                        media.globalId
                                                    ),
                                                    refetchedMedia,
                                                  ],
                                                };
                                              });
                                            } else {
                                              setVideos((prevVideos) => {
                                                const currentVideos =
                                                  prevVideos[
                                                    session.globalId
                                                  ] || [];
                                                return {
                                                  ...prevVideos,
                                                  [session.globalId]: [
                                                    ...currentVideos.filter(
                                                      (video) =>
                                                        video.globalId !==
                                                        media.globalId
                                                    ),
                                                    refetchedMedia,
                                                  ],
                                                };
                                              });
                                            }
                                          }
                                        } catch (error) {
                                          console.error(
                                            "Error refreshing URL:",
                                            error
                                          );
                                        }
                                      }}
                                    />
                                    <div
                                      className="overlay"
                                      onClick={() =>
                                        openGallery(media, mediaArray)
                                      }
                                    ></div>
                                    <input
                                      type="checkbox"
                                      id="myCheckbox"
                                      checked={
                                        !!multiselectedFiles.find(
                                          (item) =>
                                            item.globalId === media.globalId
                                        )
                                      }
                                      onChange={() => {
                                        handleSelectImage(media);
                                        handleImageClick(
                                          media.url,
                                          media.downloadURL
                                        );
                                      }}
                                    />
                                    <label htmlFor="myCheckbox"></label>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>
                  </TabPanel>
                ) : (
                  ""
                )}
                {videos && largestSubscriptionId === 1 ? (
                  <TabPanel
                    value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                    index={1}
                  >
                    <div className="mini-panel-container">
                      <img src={info} className="mini-panel-img"></img>
                      <div>
                        <div className="mini-panel-header">
                          Video uploads are available for Standard and Premium
                          Tiers.
                        </div>
                        <div>
                          To access your videos in MicroREC Connect, check out
                          the{" "}
                          <a
                            href="/subscription/#subs"
                            className="mini-panel-link"
                          >
                            upgrade options.
                          </a>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel
                    value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                    index={1}
                  >
                    <div className="images_page">
                      {(() => {
                        const mediaArray = [...(videos || [])].sort(
                          (a, b) =>
                            new Date(b.createdAt.toDate()) -
                            new Date(a.createdAt.toDate())
                        );
                        return [...Array(mediaArray.length)].map((_, index) => {
                          const media = mediaArray[index];
                          return (
                            <React.Fragment key={`${id}_${index}`}>
                              <div>
                                <>
                                  <div className="hover_over">
                                    <>
                                      <link
                                        rel="preload"
                                        as="image"
                                        href={media.thumbnailSessionURL}
                                      ></link>
                                      <div className="image-container">
                                        <div className="pause_button">
                                          <img src={pause}></img>
                                        </div>
                                        <img
                                          src={media.thumbnailSessionURL}
                                          alt=""
                                          className="myimg"
                                          onError={async (e) => {
                                            try {
                                              const refetchedMedia =
                                                await firestoreRepository.refetchDownloadUrls(
                                                  entityId.current,
                                                  isOrgUser.current,
                                                  media
                                                );

                                              setVideos((prevVideos) => ({
                                                ...prevVideos,
                                                [session.globalId]: [
                                                  ...(prevVideos[
                                                    session.globalId
                                                  ]
                                                    ? prevVideos[
                                                        session.globalId
                                                      ].filter(
                                                        (video) =>
                                                          video.globalId !==
                                                          media.globalId
                                                      )
                                                    : []),
                                                  refetchedMedia,
                                                ],
                                              }));
                                            } catch (error) {
                                              console.error(
                                                "Error refreshing URL:",
                                                error
                                              );
                                            }
                                          }}
                                        ></img>
                                        <div
                                          className="overlay"
                                          onClick={() =>
                                            openGallery(media, mediaArray)
                                          }
                                        ></div>
                                        <input
                                          type="checkbox"
                                          id="myCheckbox"
                                          checked={
                                            !!multiselectedFiles.find(
                                              (item) =>
                                                item.globalId === media.globalId
                                            )
                                          }
                                          onChange={() => {
                                            handleSelectImage(media);
                                            handleImageClick(
                                              media.url,
                                              media.downloadURL
                                            );
                                          }}
                                        />
                                        <label for="myCheckbox"></label>
                                      </div>
                                    </>
                                  </div>
                                </>
                              </div>
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>
                  </TabPanel>
                )}
                {images ? (
                  <TabPanel
                    value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                    index={2}
                  >
                    <div className="images_page">
                      {(() => {
                        if (!Array.isArray(images)) {
                          console.error(
                            "Expected images to be an array, but got:",
                            images
                          );
                          return null; // Skip downstream code if images is not an array
                        }
                        const mediaArray = images.sort(
                          (a, b) =>
                            new Date(b.createdAt.toDate()) -
                            new Date(a.createdAt.toDate())
                        );

                        return mediaArray.map((media, index) => (
                          <React.Fragment key={`${id}_${index}`}>
                            <div>
                              <>
                                <div className="hover_over">
                                  <>
                                    <link
                                      rel="preload"
                                      as="image"
                                      href={media.thumbnailSessionURL}
                                    ></link>
                                    <div className="image-container">
                                      <img
                                        src={media.thumbnailSessionURL}
                                        alt=""
                                        className="myimg"
                                        onError={async (e) => {
                                          try {
                                            const refetchedMedia =
                                              await firestoreRepository.refetchDownloadUrls(
                                                entityId.current,
                                                isOrgUser.current,
                                                media
                                              );
                                            setImages((prevImages) => ({
                                              ...prevImages,
                                              [session.globalId]: [
                                                ...(prevImages[session.globalId]
                                                  ? prevImages[
                                                      session.globalId
                                                    ].filter(
                                                      (image) =>
                                                        image.globalId !==
                                                        media.globalId
                                                    )
                                                  : []),
                                                refetchedMedia,
                                              ],
                                            }));
                                          } catch (error) {
                                            console.error(
                                              "Error refreshing URL:",
                                              error
                                            );
                                          }
                                        }}
                                      ></img>
                                      <div
                                        className="overlay"
                                        onClick={() =>
                                          openGallery(media, mediaArray)
                                        }
                                      ></div>
                                      <input
                                        type="checkbox"
                                        id="myCheckbox"
                                        checked={
                                          !!multiselectedFiles.find(
                                            (item) =>
                                              item.globalId === media.globalId
                                          )
                                        }
                                        onChange={() => {
                                          handleSelectImage(media);
                                          handleImageClick(
                                            media.url,
                                            media.downloadURL
                                          );
                                        }}
                                      />
                                      <label htmlFor="myCheckbox"></label>
                                    </div>
                                  </>
                                </div>
                              </>
                            </div>
                          </React.Fragment>
                        ));
                        // }
                        // );
                      })()}
                    </div>
                  </TabPanel>
                ) : (
                  ""
                )}
                {galleryVisible ? (
                  <Gallery
                    sessionId={id}
                    galleryVisible={galleryVisible}
                    entityId={entityId.current}
                    isOrgUser={isOrgUser.current}
                    reloadCallback={() => setNeedReload(!needReload)}
                    openedFile={openedFile}
                    setOpenedFile={setOpenedFile}
                    setGalleryVisible={setGalleryVisible}
                    multiselectedUrls={multiselectedUrls}
                    multiselectedFiles={multiselectedFiles}
                    setMultiselectedFiles={setMultiselectedFiles}
                    setMultiselectedUrls={setMultiselectedUrls}
                    allFiles={allFiles}
                    downloadingFiles={downloadingFiles}
                    setDownloadingFiles={setDownloadingFiles}
                    dltPermission={dltPermission.current}
                    shrPermission={shrPermission.current}
                    sessionOwner={session.owner}
                    currentUser={currentUser.uid}
                    sessionData={session}
                    patientId={patient.patientId}
                  />
                ) : (
                  ""
                )}
              </Container>
            )}
          </>
        </>
      )}
    </Layout>
  );
}

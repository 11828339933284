import React from "react";
import missing_page from "../images/dashboard/404.svg";
import question from "../images/dashboard/question.svg";

const MissingPage = () => {
  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom("showNewMessage", "I have issues finding a page");
    }
  };

  const goBackAndReload = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <img src={missing_page} style={{ maxWidth: "100%" }}></img>
      <h1>Page Not Found</h1>
      <h1
        style={{
          fontWeight: "500",
          marginTop: "0px",
          padding: "0px 20px",
          maxWidth: "550px",
          marginBottom: "0",
          fontSize: "26px",
        }}
      >
        We can't find the page you're looking for.
      </h1>
      <div style={{ padding: "10px 0px", fontSize: "20px", maxWidth: "600px" }}>
        Don't worry! You can return to the previous page or explore our other
        sections. If you believe this is an error, our support team is ready to
        assist you.
      </div>
      <div>
        <button
          onClick={goBackAndReload}
          style={{
            backgroundColor: "#8F4FFF",
            borderRadius: "20px",
            padding: "10px 30px",
            border: "none",
            color: "white",
            fontSize: "18px",
            marginTop: "20px",
            cursor: "pointer",
          }}
        >
          Go Back
        </button>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <img src={question} style={{ marginRight: "10px" }}></img>
        <div
          style={{ color: "#8F4FFF", cursor: "pointer" }}
          onClick={openIntercom}
        >
          Need help? Chat with our support team
        </div>
      </div>
    </div>
  );
};

export default MissingPage;
